<template>
    <GenericButton v-if="!hasChildren('default')" :size="size" :hide-icon="!showIcon" :href="href" :theme="btnTheme" class="w-full" button-class="justify-between">
        {{ title }}
    </GenericButton>

    <Popover v-else-if="xlAndGreater" class="relative" v-slot="{ open }">
        <PopoverButton>
            <GenericButton :href="href" :theme="btnTheme" :hide-icon="!showIcon" :size="size" class="w-full" button-class="justify-between">
                {{ title }}
            </GenericButton>
        </PopoverButton>

        <PopoverPanel class="absolute z-10">
            <slot></slot>
        </PopoverPanel>
    </Popover>

    <div v-else>
        <GenericButton v-if="!currentActiveSection" as="button" @click="goToSecondLevel" theme="black" size="md" class="w-full" button-class="justify-between">
            {{ title }}
        </GenericButton>

        <transition
            enter-from-class="scale-50 opacity-50"
            enter-to-class="scale-100 opacity-100"
            enter-active-class="transition duration-600"
            leave-from-class="opacity-100"
            leave-to-class="opacity-0"
            leave-active-class="transition duration-200 ease-in-out">
            <div v-if="currentActiveSection?.title === title"
                class="absolute inset-0 bg-white overflow-y-auto rounded-t-md pt-8 w-full z-10">
                <div class="container">
                    <h4 class="heading-3 font-bold text-brand-gold">{{ currentActiveSection?.title }}</h4>
                    <div class="border-b-[.5px] border-brand-gray-100 pb-4"></div>
                    <slot></slot>
                </div>
            </div>
        </transition>

    </div>
</template>

<script setup lang="ts">
import { BREAKPOINTS } from '@/modules/core/constants';
import { useBreakpoints } from '@vueuse/core';
import { GenericButton } from '../ui';
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/vue';
import { computed, inject, reactive, ref, toRef } from 'vue';
import { INJECTION_KEY, MainNavigationSectionState } from './injection-keys';
import { useSlots } from "vue";

const breakpoints = useBreakpoints(BREAKPOINTS);
const xlAndGreater = breakpoints.greater('xl');

const slots = useSlots();

const hasChildren = (name: string) => {
    return !!slots[name];
}

interface Props {
    title: string;
    href?: string;
    isHomePage?: boolean;
    showIcon?: boolean;
    btnColor?: 'white' | 'black';
    size?: 'default' | 'sm' | 'md';
}

const props = withDefaults(defineProps<Props>(), {
    size: 'md',
    showIconDesktop: false,
});

const sharedContext = inject(INJECTION_KEY)!;

const isSubpageActive = ref(false);

const context = reactive<MainNavigationSectionState>({
    title: toRef(props, 'title', ''),
    // title: toRef(props.title),
    isSubpageActive
})


const btnTheme = computed(() => {
    if(!xlAndGreater.value) return 'black';
    return !sharedContext.isDocked ? 'black' : props.btnColor;
});

const init = () => {
    const existingIndex = sharedContext.sections.findIndex(x => {
        return x.title === props.title;
    });

    if (existingIndex === -1) {
        sharedContext.sections.push(context);
        return;
    }

    sharedContext.sections.splice(existingIndex, 1, context);
}

const goToSecondLevel = () => {
    const existingIndex = sharedContext.sections.findIndex(x => x.title === props.title);

    if (existingIndex != -1) {
        sharedContext.sections[existingIndex].isSubpageActive = true;
    }
}

const currentActiveSection = computed(() => {
    return sharedContext.sections.find(item => item.isSubpageActive);
});

init();

</script>
