<template>
    <input
        v-if="as === 'input'"
        ref="genericInputEl"
        :placeholder="formFloating ? ' ' : placeholder"
        :class="cn(
            variants({
                intent: formFloating ? 'formFloating' : 'default',
                state: errorMessage ? 'invalid' : undefined,
                iconPosition: iconName && iconPosition ? iconPosition : undefined
            }),
            props.class
        )"
    >
    <textarea
        v-else
        ref="genericInputEl"
        :placeholder="formFloating ? ' ' : placeholder"
        :class="cn(
            variants({
                intent: formFloating ? 'formFloating' : 'default',
                state: errorMessage ? 'invalid' : undefined,
                iconPosition: iconName && iconPosition ? iconPosition : undefined
            }),
            props.class
        )"
        v-textarea-auto-grow
    ></textarea>
</template>

<script lang="ts" setup>
import { cn } from '@/modules/core/utilities';
import { cva } from 'class-variance-authority';
import { ref } from 'vue';
import { TextareaAutoGrow as vTextareaAutoGrow } from '@/modules/core/directives';

interface Props {
    class?: string;
    placeholder?: string;
    errorMessage?: string;
    iconName?: string;
    showArrow?: boolean;
    inputRight?: boolean;
    iconPosition?: 'left'|'right';
    formFloating?: boolean;
    as?: 'textarea'|'input';
}

const props = withDefaults(defineProps<Props>(), {
    iconPosition: 'left',
    as: 'input'
})

const genericInputEl = ref<HTMLInputElement|null>(null);

const variants = cva('generic-input block w-full bg-white body-2 border-0 border-b border-brand-gray-100 disabled:opacity-50 focus:ring-0 focus:border-brand-gold disabled:cursor-not-allowed disabled:bg-gray-100 transition', {
    variants: {
        intent: {
            default: 'pt-4 pb-2 px-0',
            formFloating: 'pb-2 px-4 sm:pb-2.5 pt-6 sm:pt-7 peer placeholder:text-transparent h-[55px] md:h-[60px]',
        },
        state: {
            invalid: 'border-red-500 focus:ring-red-500 focus:border-red-500'
        },
        iconPosition: {
            left: 'ps-12',
            right: 'pe-12'
        },
    },
    defaultVariants: {}
})

defineExpose({ genericInputEl })
</script>

<style>

.generic-input::-ms-reveal,
.generic-input::-ms-clear,
.generic-input::-webkit-search-cancel-button {
    display: none;
}

</style>
