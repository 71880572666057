<template>
    <Disclosure :defaultOpen="defaultOpen">
        <div :class="cn('border border-brand-gray-100 rounded-sm p-5 lg:p-6 w-full transition duration-500', {
            'border-brand-red': open
        })">
            <DisclosureButton class="flex flex-col w-full" v-slot="{ open }" @click="toggleDisclosure">
                <div class="flex justify-between items-start lg:items-center w-full mb-2 lg:mb-0">
                    <slot name="header"></slot>
                    <Icon
                        symbol="chevron-up"
                        class="w-6 h-6 transition"
                        :class="!open && 'rotate-180'">
                    </Icon>
                </div>
            </DisclosureButton>

            <transition
                enter-active-class="transition duration-200 ease-[cubic-bezier(.4,0,.2,1)]"
                enter-from-class="-translate-y-1 opacity-0"
                enter-to-class="translate-y-0 opacity-100"
                leave-active-class="transition duration-200 ease-[cubic-bezier(.4,0,.2,1)]"
                leave-from-class="translate-y-0 opacity-100"
                leave-to-class="-translate-y-1 opacity-0">
                <DisclosurePanel class="flex flex-col gap-1 lg:gap-4">

                <slot name="content"></slot>
                </DisclosurePanel>
            </transition>
        </div>
    </Disclosure>
</template>

<script setup lang="ts">
import { Icon } from '@/modules/core/components';
import { cn } from '@/modules/core/utilities';
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/vue';
import { ref } from 'vue';

interface Props {
    defaultOpen: boolean;
}

const props = withDefaults(defineProps<Props>(),{
    defaultOpen: false
})

const open = ref(props.defaultOpen);

const toggleDisclosure = () => {
  open.value = !open.value;
};

</script>
