<template>
    <transition
        v-bind="$attrs"
        :css="false"
        @enter="onEnter">
        <div :class="[
            'slide-y-up-transition',
            isInView ? 'in-view': ''
        ]">
            <slot></slot>
        </div>
    </transition>
</template>

<script lang="ts" setup>
import { inView } from 'motion';
import { ref } from 'vue';

const motionOK = !window.matchMedia('(prefers-reduced-motion)').matches;

const props = defineProps({
    delay: Number,
    target: {
        type: String,
        required: true
    },
    duration: {
        type: Number,
        default: .3
    }
})

const isInView = ref(false);

const onEnter = (el: Element) => {
    if (!motionOK) return;

    const els = el.querySelectorAll(props.target);
    els.forEach((el, key) => {
        (el as HTMLElement).style.setProperty('--index', key.toString())
        el.classList.add('slide-y-up-transition-target')
    })

    window.addEventListener('load', () => {
        inView(el, () => {
            isInView.value = true;
        })
    }, { once: true })
}

</script>

<style>

@media not (prefers-reduced-motion) {

    .slide-y-up-transition-target {
        transform: translateY(60px);
        opacity: 0;
    }

    html.loaded {
        .slide-y-up-transition-target {
            transition-delay: calc(var(--index) * 100ms);
            transition-property: opacity, transform;
            transition-duration: .6s;
            transition-timing-function: cubic-bezier(.215, .61, .355, 1);
        }

        .slide-y-up-transition.in-view {
            .slide-y-up-transition-target {
                opacity: 1;
                transform: none;
            }
        }
    }
}
</style>