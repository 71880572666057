<template>
    <div ref="rootElRef" :id="id">
        <slot></slot>
    </div>
</template>

<script setup lang="ts">
import { useIntersectionObserver } from '@vueuse/core';
import { inject, reactive, ref } from 'vue';
import { KEY, PageOutlineSection } from './injection-keys';

interface Props {
    id: string;
}

const props = defineProps<Props>()

const rootElRef = ref<HTMLElement>();

const state = reactive<PageOutlineSection>({
    id: props.id,
    isActive: false
})

const sharedContext = inject(KEY)!;

const updateSharedContext = () => {
    const index = sharedContext.sections.findIndex(x => x.id === props.id);
    if (index === -1) {
        sharedContext.sections.push(state);
        return;
    }
    sharedContext.sections.splice(index, 1, state)
}

useIntersectionObserver(rootElRef, ([{ isIntersecting }], observerElement) => {
    state.isActive = isIntersecting;
    // updateQueryParam();
}, { rootMargin: "-20% 0px" })


const updateQueryParam = () => {
    const url = new URL(window.location.href);
    url.hash = props.id ? '#' + props.id : '';
	window.history.replaceState(null, '', url.toString());
}

updateSharedContext()
</script>

<style scoped>

</style>
