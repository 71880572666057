<template>
    <ul class="[&>*:last-child]:border-b-0">
        <component v-for="item in getSlotChildren(sharedContext?.navSlot, { activeSectionId, events: { scrollToSection } })"
            :is="item">
        </component>

    </ul>
</template>

<script setup lang="ts">
import { computed, inject } from 'vue';
import { KEY } from './injection-keys';
import { getSlotChildren, seamlessScrollTo } from '../../utilities';

const sharedContext = inject(KEY)!;

const activeSectionId = computed(() => sharedContext.sections.find(x => x.isActive)?.id)

function scrollToSection(id: string) {
    seamlessScrollTo(id)
}

</script>

<style scoped></style>
