<template>
    <RadioGroup
        v-model="inputValue"
        @update:modelValue="value => onHandleChange(value)"
        :disabled="disabled"
        class="scroll-mt-24"
        :class="{
            'is-invalid': !!errorMessage,
        }">
        <RadioGroupLabel v-if="srOnlyLabel" class="sr-only">
            {{ srOnlyLabel }}
        </RadioGroupLabel>
        <div :class="cn('flex items-center space-x-2', groupOptionsClass)">
            <RadioGroupOption
                v-for="(item, i) in items"
                :key="`${idAttr}-option-${i}`"
                :name="name"
                :value="getItemValue(item)"
                :disabled="disabled || getDisabledValue(item)"
                v-slot="{ active, checked }"
                class="group focus:outline-none ui-disabled:opacity-50 ui-disabled:pointer-events-none"
                :class="optionClass"
            >
                <div :class="radioOptionVariants({
                    status: getDisabledValue(item) === true ? 'disabled' : 'enabled',
                    checked: checked,
                    class: innerOptionClass
                })">
                    <RadioGroupLabel as="span">
                        <slot name="radio-group-label" :item="item" :index="i" :checked="checked" :getItemText="getItemText" class="transition-colors">
                            {{ getItemText(item) }}
                        </slot>
                    </RadioGroupLabel>
                </div>
            </RadioGroupOption>
        </div>
        <p
            v-if="hint"
            :id="`${idAttr}-description`"
            class="mt-2 text-xs text-gray-500">
            {{ hint }}
        </p>
        <p
            v-if="errorMessage"
            :id="`${idAttr}-error`"
            class="block mt-2 text-xs text-red-600">
            {{ errorMessage }}
        </p>
    </RadioGroup>
</template>

<script lang="ts" setup>
import { useField } from 'vee-validate';
import { RadioGroup, RadioGroupLabel, RadioGroupOption } from '@headlessui/vue';
import { cva, type VariantProps } from "class-variance-authority";
import { commonSelectionFieldProps } from '../../shared';
import { PropType, computed, toRef } from 'vue';
import { kebabCase } from 'change-case'
import { cn } from '@/modules/core/utilities';


const props = defineProps({
    ...commonSelectionFieldProps,
    items: {
        type: Array as PropType<any[]>,
        default: () => [],
        required: true
    },
    name: {
        type: String,
        required: true
    },
    srOnlyLabel: String,
    groupOptionsClass: String,
    optionClass: {
        type: String,
        default: ''
    },
    innerOptionClass: {
        type: String,
        default: ''
    }
})

const emits = defineEmits<{
    (event: 'update:modelValue', data: string|number|Array<string|number>): void,
    (event: 'input-value-changed', data: string|number|Array<string|number>): void
}>();

const radioOptionVariants = cva('flex items-center justify-center px-4 py-4 rounded-full w-full ring-0 outline-none focus:outline-none transition duration-150', {
    variants: {
        status: {
            enabled: 'cursor-pointer',
            disabled: 'cursor-not-allowed opacity-25 bg-brand-gray-100'
        },
        checked: {
            true: 'text-brand-red border border-brand-red',
            false: 'text-brand-gray-200 border border-brand-gray-200'
        }
    },
})

type RadioGroupOptionProps = Required<VariantProps<typeof radioOptionVariants>>;

const fieldName = toRef(props, 'name');
const idAttr = computed(() => props.id ?? `radio-group-${kebabCase(props.name)}`);

const {
    handleChange,
    errorMessage,
    value: inputValue
} = useField<string|number|Array<string|number>>(fieldName)

const onHandleChange = (e: unknown) => {
    emits('update:modelValue', inputValue.value);
    emits('input-value-changed', inputValue.value);
    handleChange(e)
}

const getItemValue = (item: any) => {
    if (!item) return '';
    if (typeof item === 'string' || typeof item === 'number') return item;
    return item[props.itemValue as keyof typeof item];
}

const getItemText = (item: any) => {
    if (!item) return '';
    if (typeof item === 'string' || typeof item === 'number') return item;
    if (!props.itemText) return;
    return item[props.itemText as keyof typeof item];
}

const getDisabledValue = (item: any) => {
    if (!item) return '';
    if (typeof item === 'string' || typeof item === 'number') return item;
    if (!props.itemDisabled) return;
    return item[props.itemDisabled as keyof typeof item];
}
</script>
