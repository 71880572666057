<template>
    <label
        :for="htmlFor"
        :class="cn('block body-2 mb-1', props.class)"
    >
        <slot></slot>
        <span v-if="mandatory" class="text-red-600">*</span>
    </label>
</template>

<script lang="ts" setup>
import { cn } from '@/modules/core/utilities';

interface Props {
    htmlFor?: string;
    class?: string;
    mandatory?: boolean;
}

const props = defineProps<Props>()
</script>
