<template>
    <div class="relative bg-white border-2 border-brand-red rounded-[20px] rounded-br-[60px] lg:rounded-[40px] lg:rounded-br-xl !rounded-bl-[0px]">
        <slot name="header"></slot>
        <div class="flex flex-col w-full h-full p-8 lg:p-12 lg:pt-0">
            <div class="relative w-full lg:w-8/12 z-10 ms-auto">
                <div class="grid grid-cols-2 grid-rows-2 gap-8 w-full mt-auto">
                    <slot name="items"></slot>
                </div>
            </div>

            <div class="flex items-end justify-end mt-4 xl:p-8">
                <slot name="cta"></slot>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
</script>
