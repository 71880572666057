<template>

    <nav
        :class="variants({
            state: mainNavigationState,
        })">

        <!-- Mobile View -->
        <div v-if="!xlAndGreater" class="container">
            <div class="flex justify-between items-center">
                <a href="/">
                    <slot v-if="sharedContext.isDocked" name="mobile-logo-docked"></slot>
                    <slot v-else name="mobile-logo-undocked"></slot>
                </a>

                <MainNavigationOffcanvas>
                    <template #activator="{ show }">
                        <MainNavigationHamburger
                            @click="openDrawer(show)"
                            :btn-color="btnColor"
                        ></MainNavigationHamburger>
                    </template>
                    <template #logo>
                        <slot name="mobile-logo-offcanvas"></slot>
                    </template>

                    <template #content>
                        <div class="container block h-[450px] overflow-y-scroll">
                            <ul class="flex flex-col w-full [&>*:last-child]:border-b-0">
                                <slot name="mobile-nav-items" :isHomePage="isHomePage"></slot>
                            </ul>
                        </div>
                        <div class="mt-auto">
                            <slot name="mobile-nav-bottom"></slot>
                        </div>

                    </template>
                </MainNavigationOffcanvas>
            </div>
        </div>

        <!-- Desktop View -->
        <div v-if="xlAndGreater" class="container relative flex justify-between">
            <a href="/" class="absolute left-1/2 transform -translate-x-1/2 w-full h-full" aria-label="Home page">
                <div class="flex justify-center w-full h-full">
                    <slot name="desktop-logo-docked" :isDocked="sharedContext.isDocked"></slot>
                    <slot name="desktop-logo-undocked" :isDocked="sharedContext.isDocked"></slot>
                </div>
            </a>

            <ul class="flex gap-12">
                <slot name="desktop-left-nav-items" :isHomePage="isHomePage"></slot>
            </ul>

            <div>
                <div class="w-[300px]"></div>
            </div>

            <ul class="flex gap-12">
                <slot name="desktop-right-nav-items" :is-home-page="isHomePage"></slot>
            </ul>
        </div>
    </nav>
    <div ref="ioHookRef" class="absolute h-[1px] w-full z-10 pointer-events-none"></div>
</template>

<script setup lang="ts">
import { computed, defineAsyncComponent, provide, reactive, ref } from 'vue';
import { cva } from 'class-variance-authority';
import { refAutoReset, useBreakpoints, useIntersectionObserver, useScroll } from '@vueuse/core';
import { INJECTION_KEY, MainNavigationSharedContext } from './injection-keys';
import { BREAKPOINTS } from '@/modules/core/constants';

const MainNavigationHamburger= defineAsyncComponent(() => import('./main-navigation-hamburger.vue'))
const MainNavigationOffcanvas = defineAsyncComponent(() => import('./main-navigation-offcanvas.vue'))

const ioHookRef = ref<HTMLElement>();

interface Props {
    isHomePage?: boolean;
    btnColor?: 'white' | 'black';
}

const props = defineProps<Props>();

const sharedContext = reactive<MainNavigationSharedContext>({
    sections: [],
    isDocked: true,
    isDrawerActive: false,
    isHomePage: props.isHomePage,
    lastDirections: { top: false, bottom: false }
});

provide(INJECTION_KEY, sharedContext);

const breakpoints = useBreakpoints(BREAKPOINTS);
const xlAndGreater = breakpoints.greater('xl');
const dockedChange150ms = refAutoReset(false, 150);

useIntersectionObserver(ioHookRef, ([{ isIntersecting }]) => {
    sharedContext.isDocked = isIntersecting;
    dockedChange150ms.value = true;
})

const { y, directions } = useScroll(
    window,
    {
        onScroll: () => {
            sharedContext.lastDirections.top = directions?.top;
            sharedContext.lastDirections.bottom = directions?.bottom;
        }
    }
);

const mainNavigationState = computed(()=> {
    return sharedContext.isDocked ? 'top' :  sharedContext.lastDirections.top ? 'bottom' : 'hide';
})

const variants = cva('fixed w-full z-50 h-[--nav-height] flex items-center transition duration-300 lg:duration-[600ms] ease-[cubic-bezier(.2,1,.2,1)]',{
    variants: {
        state: {
            top:'bg-transparent',
            bottom:'bg-white',
            hide: 'bg-transparent translate-y-[-100%]'
        },
    }
})

const openDrawer = (show: () => void) => {
    show();
    sharedContext.isDrawerActive = !sharedContext.isDrawerActive;
}

</script>
