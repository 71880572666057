<template>
    <div
        class="[&_svg]:inline-block [&_svg]:w-full [&_svg]:h-full [&_svg]:!fill-current"
        v-html="htmlContentProcessed"
    ></div>
</template>

<script setup lang="ts">
import { computed } from 'vue';


interface Props {
    htmlContent: string;
}

const props = defineProps<Props>()

const htmlContentProcessed = computed(() => {
    if (!props.htmlContent) return;

    const wrapper = document.createElement('div');
    wrapper.innerHTML = props.htmlContent;
    wrapper.querySelectorAll('[fill]').forEach(x => x.setAttribute('fill', 'currentColor'));
    
    const htmlContent = wrapper.innerHTML;

    wrapper.remove();

    return htmlContent;
})
</script>

<style scoped>

</style>