<template>
    <div :class="cn(variants({
        type: paginationType,
        color: bulletColor,
        active: isActive,
        size: bulletSize,
    }))">
        <slot></slot>
    </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { VariantProps, cva } from 'class-variance-authority';
import { Icon } from '../common';
import { cn } from '../../utilities';

interface Props {
    autoplay?: boolean;
    index?: number;
    activeIndex?: number;
    autoplayProgress?: number;
    bulletColor?: VariantProps<typeof variants>["color"];
    paginationType?: VariantProps<typeof variants>["type"];
    // bulletSize: VariantProps<typeof variants>["size"];
    bulletSize?: 'default' | 'sm' | 'md';
}

const props = defineProps<Props>();

const isActive = computed(() => props.index === props.activeIndex);

const variants = cva('', {
    variants: {
        type: {
            bullet:'rounded-full ',
            icon: '[&_svg]:w-8 [&_svg]:h-8 [&_svg]:text-white [&_svg]:text-opacity-30',
        },
        color: {
            white: '',
            gold: ''
        },
        active: {
            true: '',
            false: ''
        },
        size: {
            default: 'h-[8px] w-[8px]',
            sm: 'h-[12px] w-[12px]',
            md: 'h-[14px] w-[14px]',
        }
    },
    compoundVariants: [
        {
            type: 'bullet',
            color: 'white',
            active: false,
            class: 'bg-white bg-opacity-30',
        },
        {
            type: 'bullet',
            color: 'gold',
            active: false,
            class: 'bg-brand-gray-100',
        },
        {
            type: 'bullet',
            color: 'white',
            active: true,
            class: 'bg-white bg-opacity-100',
        },
        {
            type: 'bullet',
            color: 'gold',
            active: true,
            class: 'bg-brand-gold bg-opacity-100',
        },
        {
            type: 'icon',
            active: true,
            class: 'bg-transparent [&_svg]:text-opacity-100',
        }
    ],
    defaultVariants: {
        type: 'bullet',
        color: 'white',
        size: 'default'
    }
})

</script>

<style scoped></style>
