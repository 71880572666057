<template>
    <SwiperVue
        :modules="[Scrollbar, Autoplay]"
        class="generic-carousel select-none"
        v-bind="swiperConfig"
        @swiper="onSwiper"
        @slide-change="onSlideChange"
        :speed=300
        round-lengths
        mousewheel
        grab-cursor
        watch-slides-progress>
        <slot></slot>

        <slot name="swiper-control" slideToPrev="slideToPrev" slideToNext="slideToNext">
            <div :class="cn(swiperControlVariants({ theme: swiperThemeColor, swiperControlsPosition }), swiperControlClass)">
                <button
                    v-if="enableNavigation && slideMoreThanPredefined"
                    @click="slideToPrev"
                    :disabled="selectedPagination.activeIdx == 0 ? true : false "
                    :class="cn('transition flex items-center justify-center [&_svg]:hover:text-black hover:border-black [&_svg]:disabled:text-gray-200 disabled:border-gray-200 disabled:pointer-events-none duration-200 ease-in', navBtnClass)"
                    aria-label="Previous Button">
                    <icon symbol="circle-arrow-left" class="w-10 h-10 me-1"></icon>
                </button>
                <div v-if="enablePagination && slideMoreThanPredefined" :class="cn('flex gap-2', paginationClass)">
                    <CarouselBullet
                        v-for="(bullet, index) in selectedPagination.totalIdx"
                        :index="index"
                        :pagination-type="swiperPaginationType"
                        :bullet-color="swiperThemeColor"
                        :bullet-size="bulletSize"
                        :active-index="selectedPagination.activeIdx">
                        <slot name="custom-pagination"></slot>
                    </CarouselBullet>
                </div>
                <button
                    v-if="enableNavigation && slideMoreThanPredefined"
                    @click="slideToNext"
                    :disabled="selectedPagination.activeIdx == selectedPagination.totalIdx - 1 ? true : false "
                    :class="cn('transition flex items-center justify-center [&_svg]:hover:text-black hover:border-black [&_svg]:disabled:text-gray-200 disabled:border-gray-200 disabled:pointer-events-none duration-200 ease-in', navBtnClass)"
                    aria-label="Next Button">
                    <icon symbol="circle-arrow-right" class="w-10 h-10 ms-1"></icon>
                </button>
            </div>
        </slot>
    </SwiperVue>
</template>

<script setup lang="ts">
import Swiper from 'swiper';
import {  Swiper as SwiperVue } from 'swiper/vue';
import { Autoplay, Scrollbar } from 'swiper/modules';
import { computed, reactive, ref, useAttrs } from 'vue';
import { Icon } from '@/modules/core/components'
import CarouselBullet from './carousel-bullet.vue';

import 'swiper/css';
import 'swiper/css/pagination';
import { cn } from '../../utilities';
import { VariantProps, cva } from 'class-variance-authority';

interface Props {
    scrollbars?: boolean;
    pagination?: boolean;
    autoplay?: boolean;
    autoplayDelay?: number;
    enableNavigation?: boolean;
    enablePagination?: boolean;
    swiperControlClass?: string;
    swiperControlsPosition?: VariantProps<typeof swiperControlVariants>["swiperControlsPosition"];
    swiperThemeColor?: VariantProps<typeof swiperControlVariants>["theme"];
    swiperPaginationType?: 'bullet' | 'icon';
    bulletSize?: 'default' | 'md';
    paginationClass?: string;
    navBtnClass?: string;
}

const attrs = useAttrs();

const props = withDefaults(defineProps<Props>(), {
    autoplayDelay: 3000,
    enablePagination: true,
    enableNavigation: true,
    swiperControlsPosition: 'center',
})

const isGroupSlides = attrs['slides-per-group'] || 0 ;

const swiperControlVariants = cva('absolute bottom-0 z-10 flex items-center gap-4 left-0 translate-x-0', {
    variants: {
        theme: {
            white: '[&_button]:border-white [&_button_svg]:text-white',
            gold: '[&_button]:border-brand-gold [&_button_svg]:text-brand-gold'
        },
        swiperControlsPosition: {
            centerDesktop: 'lg:left-1/2 lg:-translate-x-1/2',
            center: 'left-1/2 -translate-x-1/2',
            left: '',
            hero: 'left-1/2 -translate-x-1/2 bottom-28 lg:bottom-24'
        }
    },
    defaultVariants: {
        swiperControlsPosition: 'center',
        theme: 'white',
    }
})

const swiperConfig = ref<InstanceType<typeof SwiperVue>['$props']>({})

if (props.scrollbars) {
    swiperConfig.value.scrollbar = {
        enabled: true,
        draggable: true
    }
}

if (props.autoplay) {
    swiperConfig.value.autoplay = {
        pauseOnMouseEnter: false,
        disableOnInteraction: false,
        delay: props.autoplayDelay
    }
}

const selectedPagination = computed(() => {

    if (isGroupSlides) {
        return {
            activeIdx: pagination.activeGroupIndex,
            totalIdx: pagination.totalGroups,
        }
    }

    return {
        activeIdx: pagination.activeIndex,
        totalIdx: pagination.totalSlides,
    }
});

const pagination = reactive({
    activeIndex: 0,
    activeGroupIndex: 0,
    totalSlides: 0,
    totalGroups: 0,
})

const swiperInstance = ref<Swiper>();

const slideMoreThanPredefined = ref(false);

const onSwiper = (swiper: Swiper) => {
    swiperInstance.value = swiper;
    pagination.activeIndex = swiper.realIndex;
    pagination.totalSlides = swiper.slides.length;

    if (isGroupSlides && swiper.params.slidesPerGroup) {
        pagination.totalGroups = Math.ceil(swiper.slides.length / swiper.params.slidesPerGroup);
    }

    checkSlideIsMoreThanPreDefined(swiper);
}

const onSlideChange = (swiper: Swiper) => {
    pagination.activeIndex = swiper.realIndex;
    pagination.activeGroupIndex = swiper.snapIndex;
    pagination.totalSlides = swiper.slides.length;
}

const slideToPrev = () => {
    if (swiperInstance.value) {
        swiperInstance.value.slidePrev();
    }
};

const slideToNext = () => {
    if (swiperInstance.value) {
        swiperInstance.value.slideNext();
    }
};

const checkSlideIsMoreThanPreDefined = (swiper: Swiper) => {

    // watch-slides-progress
    // This function need to make sure that the "watch-slides-progress" is defined.
    const currentVisibleSlides = swiper.slides.filter(item => item.classList.contains("swiper-slide-visible"));
    const definedSlidePerView = swiper.params.slidesPerView;
    if (definedSlidePerView != "auto" && currentVisibleSlides.length >= Number(definedSlidePerView) && swiper.slides.length > 1) {
        slideMoreThanPredefined.value = true;
    }
}

</script>

<style>
.swiper-wrapper {
    transition-delay: 0.113554ms;
    -webkit-transition-property: -webkit-transform;
    transition-property: -webkit-transform;
    transition-property: transform;
    transition-property: transform,-webkit-transform;
}

.generic-carousel .swiper-scrollbar {
    height: 4px;
    margin-top: 2rem;
    position: relative;
    border-radius: 999px;
}

.swiper-pagination {
    @apply flex;
}

.swiper-pagination-bullet {
    @apply bg-white opacity-30;

    &-active {
        @apply bg-white opacity-100;
    }
}

.swiper-horizontal .swiper-pagination-bullets {
    @apply relative w-auto bottom-auto;
}
</style>
