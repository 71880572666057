<template>
    <video
        ref="videoRef"
        :data-src="videoSrc"
    ></video>
</template>

<script lang="ts" setup>
import { ref, onMounted } from "vue";
import { useIntersectionObserver } from "@vueuse/core";

interface Props {
    videoSrc: string;
}

const props = defineProps<Props>();
const videoRef = ref<HTMLVideoElement>();

const videoIsVisible = ref(false);

const { stop } = useIntersectionObserver(
    videoRef,
    ([{ isIntersecting }], observerElement) => {
        if (videoRef.value && isIntersecting && !videoIsVisible.value) {
            videoIsVisible.value = isIntersecting;
            const videoSrc = videoRef.value.dataset.src;
            if (videoSrc) {
                videoRef.value.src = videoSrc;
                videoRef.value.load();
            }
        }
    }
);
</script>
