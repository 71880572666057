<template>
    <div
        class="scroll-mt-24"
        :class="{
            'is-invalid': !!errorMessage,
        }"
    >
        <GenericLabel
            v-if="!formFloating && label"
            :html-for="idAttr"
            :class="labelClass">
            <slot name="label-content">
                {{ label }}
                <span v-if="mandatory" class="text-brand-red">*</span>
            </slot>
        </GenericLabel>
        <GenericInputIconWrapper
            :icon-name="iconName"
            :show-reveal-button="type === 'password'"
            @reveal="toggleReveal"
            :reveal-active="revealActive"
            :icon-position="iconPosition"
            :icon-class="iconClass">
            <GenericInput
                @input="onHandleChange"
                @blur="handleBlur"
                ref="genericTextField"
                :type="inputType"
                :id="idAttr"
                :label="label"
                :name="name"
                :placeholder="placeholder"
                :value="inputValue"
                :disabled="disabled"
                :readonly="readonly"
                :autocomplete="autocomplete"
                :maxlength="maxlength"
                :form-floating="formFloating"
                :error-message="errorMessage"
                :icon-name="iconName"
                :icon-position="iconPosition"
                :show-arrow="showArrow"
                :required="required"
                :class="fieldClass"
                :as="as"
            />
            <slot></slot>
            <GenericFloatingLabel
                v-if="formFloating && label"
                :html-for="idAttr"
                :icon-name="iconName"
                :icon-position="iconPosition"
                :error-message="errorMessage"
            >
                <slot name="label-content">
                    {{ label }}
                </slot>
            </GenericFloatingLabel>
        </GenericInputIconWrapper>
        <p v-if="!!maxlength" class="float-right text-[14px] lg:text-[16px] font-avenir mt-2"><small>{{ charactersLeft }} / {{ maxlength }}</small></p>
        <p
            v-if="hint"
            :id="`${idAttr}-description`"
            class="mt-2 text-xs text-gray-500"
        >
            {{ hint }}
        </p>
        <p
            v-if="errorMessage"
            :id="`${idAttr}-error`"
            class="block mt-2 text-xs text-red-600">
            {{ errorMessage }}
        </p>
    </div>
</template>

<script lang="ts" setup>
import { useField } from "vee-validate";
import {
    GenericInput,
    GenericInputIconWrapper,
    GenericLabel,
    GenericFloatingLabel
} from '../../generic';
import { commonFieldProps } from '../../shared';
import { kebabCase } from 'change-case'
import { ref, computed } from "vue";

const props = defineProps({
    ...commonFieldProps,
    name: {
        type: String,
        required: true
    },
    step: Number,
    min: Number,
    max: Number,
    formFloating: Boolean,
    modelValue: String
})

const genericTextField = ref<InstanceType<typeof GenericInput> | null>(null);
defineExpose({ genericTextField });

const emit = defineEmits(['update:modelValue', 'textInput']);

const idAttr = computed(() => props.id ?? kebabCase(props.name));

const {
    value: inputValue,
    errorMessage,
    handleBlur,
    handleChange,
} = useField(props.name, undefined, {
    initialValue: props.value ?? props.modelValue,
});

const revealActive = ref(false);
const toggleReveal = () => revealActive.value = !revealActive.value;
const inputType = computed(() => {
    if (props.type === 'password' && revealActive.value) return 'text';
    return props.type;
})
const charactersLeft = computed(() => Math.max((props.maxlength ?? 0) - (inputValue.value?.length ?? 0), 0));

const onHandleChange = (e: unknown) => {
    handleChange(e);
    emit('update:modelValue', inputValue.value);
    emit('textInput', e);
}

</script>
