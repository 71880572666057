<template>
    <div
        class="scroll-mt-24"
        :class="{
            'is-invalid': !!errorMessage,
        }"
    >
        <GenericLabel
            v-if="!formFloating && label"
            :html-for="idAttr"
            :class="labelClass">
            <slot name="label-content">
                {{ label }}
                <span v-if="mandatory" class="text-brand-red">*</span>
            </slot>
        </GenericLabel>
        <GenericInputIconWrapper
            :icon-name="iconName"
            :show-arrow="showArrow"
            :icon-position="iconPosition"
            :icon-class="iconClass"
        >
            <GenericSelectEl
                @change="onHandleChange"
                @blur="handleBlur"
                :id="idAttr"
                :label="label"
                :name="name"
                :value="inputValue"
                :disabled="disabled"
                :readonly="readonly"
                :autocomplete="autocomplete"
                :form-floating="formFloating"
                :error-message="errorMessage"
                :icon-name="iconName"
                :icon-position="iconPosition"
                :show-arrow="showArrow"
                :required="required"
                :class="fieldClass">
                <slot name="options">
                    <option
                        v-if="placeholder"
                        value=""
                        disabled
                        selected
                    >
                        {{ placeholder }}
                    </option>
                    <option
                        v-for="(item, i) in items"
                        :key="i"
                        :value="getItemValue(item)"
                        class="text-gray-950"
                    >
                        {{ getItemText(item) }}
                    </option>
                </slot>
            </GenericSelectEl>
            <GenericFloatingLabel
                v-if="formFloating && label"
                :html-for="idAttr"
                :icon-name="iconName"
                :icon-position="iconPosition"
                :error-message="errorMessage"
                :has-selected-value="inputValue ? true : false"
                field-type="select"
            >
                <slot name="floating-label-content">
                    {{ label }}
                </slot>
            </GenericFloatingLabel>
        </GenericInputIconWrapper>
        <p
            v-if="hint"
            :id="`${idAttr}-description`"
            class="mt-2 text-xs text-gray-500"
        >
            {{ hint }}
        </p>
        <p
            v-if="errorMessage"
            :id="`${idAttr}-error`"
            class="block mt-2 text-xs text-red-600">
            {{ errorMessage }}
        </p>
    </div>
</template>

<script lang="ts" setup>
import { useField } from 'vee-validate';
import {
    GenericInputIconWrapper,
    GenericLabel,
    GenericFloatingLabel,
    GenericSelectEl
} from '../../generic';
import { commonSelectionFieldProps } from '../../shared';
import { PropType, computed } from 'vue';
import { kebabCase } from 'change-case'

const props = defineProps({
    ...commonSelectionFieldProps,
    items: {
        type: Array as PropType<any[]>,
        default: () => [],
        required: true
    },
    name: {
        type: String,
        required: true
    },
    formFloating: Boolean,
    showArrow: {
        type: Boolean,
        default: true
    },
    value: String,
    readonly: Boolean
})

const idAttr = computed(() => props.id ?? kebabCase(props.name));

const {
    value: inputValue,
    errorMessage,
    handleBlur,
    handleChange,
} = useField(props.name, undefined, {
    initialValue: props.value,
});

const emit = defineEmits(['update:modelValue']);

const onHandleChange = (e: unknown) => {
    handleChange(e);
    emit('update:modelValue', inputValue.value);
}

const getItemValue = (item: any) => {
    if (!item) return '';
    if (typeof item === 'string' || typeof item === 'number') return item;
    return item[props.itemValue as keyof typeof item];
}

const getItemText = (item: any) => {
    if (!item) return '';
    if (typeof item === 'string' || typeof item === 'number') return item;
    if (!props.itemText) return;
    return item[props.itemText as keyof typeof item];
}

const getDisabledValue = (item: any) => {
    if (!item) return '';
    if (typeof item === 'string' || typeof item === 'number') return item;
    if (!props.itemDisabled) return;
    return item[props.itemDisabled as keyof typeof item];
}
</script>
