<template>
    <div class="relative">
        <slot v-if="!lgAndGreater" name="header"></slot>
        <div class="block lg:absolute inset-0">
            <div
                :class="[
                    'w-[95%] lg:w-6/12',
                    placeholder === 'left' ? 'ms-auto' : 'me-auto',
                ]">
                <slot name="carousel"></slot>
            </div>
        </div>

        <div class="z-10 container">
            <div class="relative w-full">
                <div
                    :class="[
                        'lg:w-[45%]',
                        placeholder === 'left' ? 'me-auto' : 'ms-auto',
                    ]">
                    <slot name="placeholder"></slot>
                </div>
            </div>
        </div>

    </div>
</template>

<script setup lang="ts">
import { GenericCarousel, Icon } from '@/modules/core/components';
import { SwiperSlide } from 'swiper/vue';
import { GenericButton } from '.';
import { cva } from 'class-variance-authority';
import { useBreakpoints } from '@vueuse/core';
import { BREAKPOINTS } from '@/modules/core/constants';

interface Props {
    placeholder: 'left' | 'right';
}

withDefaults(defineProps<Props>(),{
    placeholder: 'right'
})

// const variants = cva('relative flex flex-col border-0 overflow-hidden', {
//     variants: {
//         borderRadius: {
//             left: 'rounded-tl-lg rounded-bl-lg',
//             right: 'rounded-tr-lg rounded-br-lg'
//         },
//     }
// })

const breakpoints = useBreakpoints(BREAKPOINTS);
const lgAndGreater = breakpoints.greater('lg');
</script>
