<template>
    <div class="ms-auto w-full h-full bg-white rounded-tl-md rounded-tr-md lg:rounded-tl-xl lg:rounded-tr-xl mb-[-.5px]">
        <div class="container py-6 min-h-[68px]">
            <SwiperVue
                class="generic-carousel !flex flex-col md:flex-row gap-4 [&_.swiper-wrapper]:md:w-10/12"
                :slides-per-view="1"
                @swiper="onSwiper"
                @slide-change="onSlideChange">
                <slot></slot>

                <div v-if="pagination.totalSlides > 1" class="bg-white w-full md:w-2/12 z-10">
                    <div class="flex justify-center lg:justify-end items-center gap-1">
                        <button
                            @click="slideToPrev"
                            :disabled="pagination.activeIndex == 0"
                            class="hover:opacity-50 duration-200 ease-in cursor-pointer disabled:text-brand-gray-100">
                            <Icon symbol="chevron-left" class="w-5 h-5"></Icon>
                        </button>

                        <div class="body-4 leading-normal"><span class="font-bold">{{ pagination.activeIndex + 1 }}</span>/{{ pagination.totalSlides }}</div>

                        <button
                            @click="slideToNext"
                            :disabled="pagination.activeIndex == pagination.totalSlides - 1"
                            class="hover:opacity-50 duration-200 ease-in cursor-pointer disabled:text-brand-gray-100">
                            <icon symbol="chevron-right" class="w-5 h-5"></icon>
                        </button>
                    </div>
                </div>
            </SwiperVue>
        </div>
    </div>

</template>

<script setup lang="ts">

import { Swiper as SwiperVue } from 'swiper/vue';
import Swiper from 'swiper';
import { reactive, ref } from 'vue';

import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Icon } from '@/modules/core/components';

const swiperInstance = ref<Swiper>();

const onSwiper = (swiper: Swiper) => {
    swiperInstance.value = swiper;
    pagination.activeIndex = swiper.realIndex;
    pagination.totalSlides = swiper.slides.length;
}

const pagination = reactive({
    activeIndex: 0,
    totalSlides: 0
})

const onSlideChange = (swiper: Swiper) => {
    pagination.activeIndex = swiper.realIndex;
    pagination.totalSlides = swiper.slides.length;
}

const slideToPrev = () => {
    if (swiperInstance.value) {
        swiperInstance.value.slidePrev();
    }
};

const slideToNext = () => {
    if (swiperInstance.value) {
        swiperInstance.value.slideNext();
    }
};

</script>

<style>
</style>
