<template>
    <div>
        <div class="flex lg:hidden flex-col min-w-[250px]">
            <Disclosure v-slot="{ open }">
                <DisclosureButton
                    :class="cn('w-full flex body-2 outline-none pt-5 pb-4 border-b-[0.5px]', {
                        '!border-b-0': open
                    })">

                    <div class="flex w-full justify-between">
                        <label class="body-2 font-bold">
                            {{ label }}
                            <span v-if="inputValue && inputValue.length" class="body-2 font-black font-avenir text-brand-gold px-4"> ({{ inputValue.length }})</span>
                        </label>

                        <Icon
                            symbol="chevron-up"
                            class="w-6 h-6 transition"
                            :class="!open && 'rotate-180'">
                        </Icon>
                    </div>

                </DisclosureButton>
                <DisclosurePanel class="">
                    <ul class="bg-white lg:p-4 w-full lg:shadow lg:max-h-[400px]">
                        <li v-for="(item, idx) in items" class="mb-4">
                            <div class="flex items-center gap-4 cursor-pointer">
                                <input :id="`${name}-${item.value}-checkbox`" type="checkbox" :checked="isSelected(item.value)" :value="item.value" class="w-5 h-5 text-brand-red bg-gray-100 border-gray-300 rounded" @click="handleOptionClick(item)">
                                <label :for="`${name}-${item.value}-checkbox`" class="body-2 text-black cursor-pointer">{{ item.label }}</label>
                            </div>
                        </li>
                    </ul>
                </DisclosurePanel>
            </Disclosure>
        </div>

        <div class="hidden lg:flex flex-col min-w-[250px]">
            <label class="hidden lg:block body-2 font-bold">
                {{ label }}
                <span v-if="inputValue && inputValue.length > 2 && !lgAndGreater" class="body-2 font-black font-avenir text-brand-gold px-4"> ({{ inputValue.length }})</span>
            </label>
            <Popover class="relative" v-slot="{ open }">
                <PopoverButton
                    ref="buttonRef"
                    :class="cn('w-full flex body-2 outline-none pt-5 pb-4 border-b-[0.5px]', {
                        '!border-b-0': open
                    })">
                    <div class="flex justify-between w-full">
                        <div v-if="inputValue && inputValue.length > 0" class="flex flex-nowrap">
                            <p class="max-w-[150px] overflow-hidden line-clamp-1 font-bold text-ellipsis">
                                {{ getSelectedLabels() }}
                            </p>
                            <span v-if="inputValue && inputValue.length > 2" class="body-2 font-black font-avenir text-brand-gold px-4"> ({{ inputValue.length }})</span>
                        </div>
                        <p v-if="inputValue && inputValue.length === 0 && placeholder" class="text-brand-gray-200 text-[16px]">{{ placeholder }}</p>
                    </div>

                    <Icon
                        symbol="chevron-up"
                        class="w-6 h-6 transition"
                        :class="!open && 'rotate-180'">
                    </Icon>
                </PopoverButton>

                <PopoverPanel
                    static
                    :class="cn('absolute z-30 hidden invisible w-full h-0', {
                        'block visible h-full' : open
                    })">
                    <ul class="bg-white pb-4 lg:p-4 w-full lg:shadow lg:max-h-[400px] overflow-y-auto">
                        <li v-for="(item, idx) in items" class="mb-4">
                            <div class="flex items-center gap-4 cursor-pointer">
                                <input :id="`${name}-${item.value}-checkbox`" type="checkbox" :checked="isSelected(item.value)" :value="item.value" class="w-5 h-5 text-brand-red bg-gray-100 border-gray-300 rounded" @click="handleOptionClick(item)">
                                <label :for="`${name}-${item.value}-checkbox`" class="body-2 text-black cursor-pointer">{{ item.label }}</label>
                            </div>
                        </li>
                    </ul>
                </PopoverPanel>
            </Popover>
        </div>
    </div>
</template>
<script setup lang="ts">
import { Popover, PopoverButton, PopoverPanel, Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/vue';

import { GenericCheckbox } from '../form';
import { cn } from '@/modules/core/utilities';
import { useField } from 'vee-validate';
import { Icon } from '@/modules/core/components';
import { onMounted } from 'vue';
import { useBreakpoints } from '@vueuse/core';
import { BREAKPOINTS } from '@/modules/core/constants';

interface Props {
    label: string;
    name: string;
    placeholder?: string;
    items: {
        value: string;
        label: string;
    }[];
    defaultValue?:  Array<string>;
}

const props = defineProps<Props>();

const emits = defineEmits<{
    (event: 'input-value-changed', data: Array<string>): void
}>();

const {
    handleChange,
    errorMessage,
    setValue,
    value: inputValue
} = useField<Array<string>>(props.name);

const breakpoints = useBreakpoints(BREAKPOINTS);
const lgAndGreater = breakpoints.greater('lg');

const handleOptionClick = (item: { value: string; label: string}) => {

    if(inputValue.value){
        const newList = [...inputValue.value];
        const index = inputValue.value.indexOf(item.value);

        if (index !== -1) {
            newList.splice(index, 1);
        } else {
            newList.push(item.value);
        }

        emits('input-value-changed', newList);
        return handleChange(newList);
    }
}

const isSelected = (id: string) => {
    if(inputValue.value && inputValue.value.find(item => item === id)) {
        return true;
    }

    return false;
}

const getSelectedLabels = () => {
    const selectedLabels = inputValue.value.map(value => {
        const item = props.items.find(item => item.value === value);
        return item ? item.label : null;
    });

    return selectedLabels.filter(label => label !== null).join('、');
}

onMounted(()=> {
    setValue([])
    if(props.defaultValue) {
        setValue(props.defaultValue)
    }
})


</script>
