import { InjectionKey, Slot } from "vue";

export interface PageOutlineSharedContext {
    sections: Array<PageOutlineSection>;
    navSlot: Slot;
}

export interface PageOutlineSection {
    id: string;
    isActive: boolean;
}

export const KEY = Symbol() as InjectionKey<PageOutlineSharedContext>;