<template>
	<nav v-if="pagination.length > 1">
		<ul class="flex justify-center items-center gap-2 ">
			<li class="page-item pr-2 h-10" :class="{ disabled: pageNumber <= 1 }">
                <button
                    @click="handlePrev"
                    :disabled="disabled || pageNumber <= 1"
                    class="[&_svg]:text-brand-gold [&_svg]:hover:text-black [&_svg]:disabled:text-gray-200 disabled:border-gray-200 disabled:pointer-events-none duration-200 ease-in"
                    type="button">
                    <span class="sr-only">Previous Page</span>
                    <Icon symbol="circle-arrow-left" class="h-10 w-10 transition duration-200 ease-in-out"></Icon>
                </button>
			</li>

			<li v-for="item in pagination" class="page-item" :class="{ active: item.isActive }" :key="pagination.indexOf(item)">
                <button
                    v-if="item.val !== '...' && !item.name"
                    @click="handleGoToPage(+item.val)"
                    :modifier="item.val === pageNumber ? 'default' : 'overlay'"
                    :class="[
                        'flex items-center justify-center p-1 rounded-full w-9 h-9',
                        item.val === pageNumber ? 'border border-brand-gold text-brand-gold' : 'border border-brand-gray-100 text-brand-gray-100'
                    ]"
                    :disabled="disabled || item.val === pageNumber">
                    {{ item.val }}
                </button>
                <button
                    v-else-if="item.name"
                    class="font-semibold hover:text-brand-purple transition-colors p-2">
                    {{ item.name }}
                </button>
				<span v-if="item.val == '...'">
					{{ item.val }}
				</span>
			</li>

			<li class="page-item pl-2 h-10">
                <button
                    @click="handleNext"
                    :disabled="disabled || pageNumber >= totalPages"
                    class="[&_svg]:text-brand-gold [&_svg]:hover:text-black [&_svg]:disabled:text-gray-200 disabled:border-gray-200 disabled:pointer-events-none duration-200 ease-in"
                    type="button">
                    <span class="sr-only">Next Page</span>
                    <Icon symbol="circle-arrow-right" class="h-10 w-10 transition duration-200 ease-in-out"></Icon>
                </button>
			</li>
		</ul>
	</nav>
</template>

<script lang="ts" setup>
import { computed } from "vue";
import { breakpointsTailwind, useBreakpoints } from "@vueuse/core";
import { Icon } from "../common";

interface PageItem {
	name?: string;
	val: number|string;
	isActive: boolean;
}

interface Props {
    pageNumber: number;
    totalPages: number;
    pagesPerViewSm?: number;
    pagesPerViewMd?: number;
    paginationClass?: string;
    disabled?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
    pagesPerViewSm: 4,
    pagesPerViewMd: 10
})

const emit = defineEmits<{
    (e: 'pageChange', value: number): void
}>()

const breakpoints = useBreakpoints(breakpointsTailwind);
const mdAndGreater = breakpoints.greater('md');

const buttonsPerView = computed((): number => {
    return mdAndGreater.value ? props.pagesPerViewMd : props.pagesPerViewSm;
});

const pagination = computed((): Array<PageItem> => {
    const range: PageItem[] = [];
    if (props.totalPages <= buttonsPerView.value) {
        for (let i = 0; i < props.totalPages; i++) {
            range.push({
                val: i + 1,
                isActive: props.pageNumber === i + 1,
            });
        }
    }
    else {
        // if totalPages > pagesPerView (default: 10)
        // get max index difference
        let maxIndex = props.totalPages - buttonsPerView.value;
        // set start as largest Number possible of pageNumber
        let start = Math.max(Math.floor(props.pageNumber - (buttonsPerView.value / 2)), 0);
        // ensure not too far from each other (lowest value)
        start = Math.min(maxIndex, start);
        for (let i = start; i < buttonsPerView.value + start; i++) {
            range.push({
                val: i + 1,
                isActive: props.pageNumber === i + 1,
            });
        }
        // when start !== 0, use ellipsis conditionally
        if (start > 0) {
            let firstLabel = "First";
            range.unshift({
                name: firstLabel,
                val: 1,
                isActive: false,
            }, {
                val: "...",
                isActive: false,
            });
        }
        if (start < maxIndex) {
            let lastLabel = "Last";
            range.push({
                val: "...",
                isActive: false,
            }, {
                name: lastLabel,
                val: props.totalPages,
                isActive: false,
            });
        }
    }
    return range;
});

const handlePrev = () => {
    emit('pageChange', Math.max(0, props.pageNumber - 1));
}

const handleGoToPage = (pageNumber: number) => {
    emit('pageChange', pageNumber);
}

const handleNext = () => {
    emit('pageChange', Math.min(props.totalPages, props.pageNumber + 1));
}

const handleSelectChange = (event: Event) => {
    const target = event.target as HTMLSelectElement;
    emit('pageChange', Number(target.value))
}
</script>

<style lang="scss">

</style>