import "virtual:svg-icons-register";

import { createApp } from "vue";
// import "./css/fonts/fonts.css";
import "./css/index.css";
import "./css/common.css";

import pinia from './stores';

import App from './website.vue';
import { setLocale } from "yup";

import * as Sentry from '@sentry/vue'

setLocale({
    mixed: {
        required: ({ label }) => `请您输入${label}`,
    },
    string: {
        email: ({ label }) => `${label}码格式不正确`,
        matches: ({ label }) => `${label}码格式不正确`,
    }
})

const app = createApp(App);

if (!import.meta.env.DEV) {
    Sentry.init({
        app,
        dsn: "https://a2ee8ee9d5885ef1e5aeaa9bf4cc3bd7@o4504790555623424.ingest.sentry.io/4505961742532608",
        environment: import.meta.env.MODE,
        integrations: [
            Sentry.browserTracingIntegration(),
            Sentry.replayIntegration({
                networkDetailAllowUrls: [
                    'https://www.taylorsuniversity.cn'
                ],
            })
        ],
        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 1.0,
        replaysSessionSampleRate: 0.001,
        replaysOnErrorSampleRate: 1.0,
    });
}


app.use(pinia);
app.mount('#app');
