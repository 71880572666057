<template>
    <svg :class="cn('custom-icon pointer-events-none fill-current', props.class)" aria-hidden="true">
        <use :href="`#icon-${symbol}`"></use>
    </svg>
</template>

<script lang="ts" setup>
import { cn } from '@/modules/core/utilities';
import { HTMLAttributes } from 'vue';

interface Props {
    symbol?: string;
    class?: HTMLAttributes['class'];
}

const props = defineProps<Props>();
</script>
