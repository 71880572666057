import dayjs from "dayjs";
import LocaleData from 'dayjs/plugin/localeData';
import utc from 'dayjs/plugin/utc';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import relativeTime from 'dayjs/plugin/relativeTime';

dayjs.extend(LocaleData);
dayjs.extend(utc);
dayjs.extend(LocalizedFormat);
dayjs.extend(relativeTime);

export function getMonths() {
    return ['January', 'February', 'March', 'April', 'May', 'June',
        'July', 'August', 'September', 'October', 'November', 'December'];
}

export function formatDateAsString(date: string | Date, formattingToken: string, isUTC = true) {
    if (typeof date === 'string' && !date.endsWith('Z')) {
        date = date + 'Z';
    }

    const dayjsDate = isUTC ? dayjs.utc(date) : dayjs(date);
    if (!dayjsDate.isValid()) return '';

    return dayjsDate.format(formattingToken);
}

export const toShortDate = (date: any) => {
    if (!date) return '';

    const theDate = dayjs(date);
    if (!theDate.isValid()) return '';

    return dayjs(date).format('DD MMM YYYY')
}

export const toLongDate = (date: any) => {
    if (!date) return '';

    const theDate = dayjs(date);
    if (!theDate.isValid()) return '';

    return dayjs(date).format('DD MMM YYYY, h:mm A')
}

export const toSuperLongDate = (date: any) => {
    if (!date) return '';

    const theDate = dayjs(date);
    if (!theDate.isValid()) return '';

    return dayjs(date).format('ddd, DD MMM YYYY, h:mm A')
}

export const to12HrTime = (date: any) => {
    if (!date) return '';

    const theDate = dayjs(date);
    if (!theDate.isValid()) return '';

    return dayjs(date).format('h:mm A')
}
