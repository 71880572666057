<template>
     <transition
        enter-active-class="transition duration-300 ease-in-out delay-75"
        enter-from-class="translate-y-4 opacity-0"
        enter-to-class="translate-y-0 opacity-100"
        leave-active-class="transition duration-150 ease-in-out"
        leave-from-class="translate-y-0 opacity-100"
        leave-to-class="translate-y-4 opacity-0"
        >
            <button
                v-if="isReachEndOfPage"
                @click="seamlessScrollTo('top')"
                type="button"
                class="[&_svg]:text-brand-red [&_svg]:hover:text-brand-gold z-20 transition">
                <Icon symbol="scroll-top" class="w-[60px] h-[60px] lg:w-[50px] lg:h-[50px]"></Icon>
            </button>
    </transition>
</template>

<script setup lang="ts">
import { Icon } from '@/modules/core/components';
import { seamlessScrollTo } from '@/modules/core/utilities';
import { useIntersectionObserver } from '@vueuse/core';
import { cva } from 'class-variance-authority';
import { onMounted, ref } from 'vue';

const isReachEndOfPage = ref(false);

onMounted(() => {
    const footer = document.querySelector('footer');
    if (!footer) return;

    const { stop } = useIntersectionObserver(
        footer,
        ([{ isIntersecting }], observerElement) => {
            isReachEndOfPage.value = isIntersecting
        },
    )

});

</script>
