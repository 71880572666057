<template>
    <slot></slot>
</template>

<script setup lang="ts">
import { provide, reactive } from 'vue';
import { KEY, PageOutlineSharedContext } from './injection-keys';

const slots = defineSlots<{
    default(props: {}): any;
    nav(props: { activeSectionId: string, events: { scrollToSection: () => void }}): any;
}>()

const sharedContext = reactive<PageOutlineSharedContext>({
    sections: [],
    navSlot: slots['nav']
})

provide(KEY, sharedContext);
</script>

<style scoped>

</style>
