<template>
    <div class="grid cols-1 lg:grid-cols-[45%_61.5%] xl:grid-cols-[40%_66.5%] w-full">
        <slot name="interest-form-image"></slot>
        <div :class="variants({
            theme: theme
        })">
            <form @submit="onSubmit">
                <div class="grid grid-cols-1 gap-5 mb-5 lg:grid-cols-2 lg:gap-8 lg:mb-8">
                    <TextField
                        name="name"
                        class=""
                        label-class="font-medium"
                        label="姓名"
                        mandatory
                    ></TextField>
                    <TextField
                        name="contactNumber"
                        placeholder="+86"
                        class=""
                        label-class="font-medium"
                        label="联系电话"
                        mandatory
                    ></TextField>
                    <TextField
                        name="emailAddress"
                        type="email"
                        class=""
                        label-class="font-medium"
                        label="邮箱"
                        mandatory
                    ></TextField>
                    <GenericSelect
                        name="stateProvinceKey"
                        label="所在省份"
                        class=""
                        label-class="font-medium"
                        placeholder="请选择"
                        :items="provinceList"
                        item-value="key"
                        item-text="name"
                        mandatory
                    ></GenericSelect>
                    <GenericSelect
                        name="educationLevelKey"
                        label="咨询学位类别"
                        class=""
                        label-class="font-medium"
                        placeholder="请选择"
                        :items="flatEducationLevelList"
                        item-value="key"
                        item-text="name"
                        @change="educationLevelKeyChanged"
                        mandatory
                    ></GenericSelect>
                    <GenericSelect
                        :key="values.educationLevelKey"
                        name="facultyKey"
                        label="咨询院系"
                        class=""
                        label-class="font-medium"
                        placeholder="请选择"
                        :items="flatFacultyLevelList"
                        item-value="key"
                        item-text="name"
                        @change="facultyKeyChanged"
                        :disabled="flatFacultyLevelList.length === 0"
                        mandatory
                    ></GenericSelect>
                    <GenericSelect
                        :key="values.facultyKey"
                        name="programmeOfInterestKey"
                        label="咨询专业"
                        class=""
                        label-class="font-medium"
                        placeholder="请选择"
                        :items="flatProgrammeList"
                        item-value="key"
                        item-text="name"
                        @change="programmeOfInterestKeyChanged"
                        :disabled="flatProgrammeList.length === 0"
                        mandatory
                    ></GenericSelect>
                    <div class="grid">
                        <label class="pb-1 font-medium body-2">入学日期<span class="text-brand-red ms-2">*</span></label>

                        <div class="flex gap-12">
                            <GenericSelect :key="values.programmeOfInterestKey"
                                           name="enrolmentYear"
                                           class="flex-grow min-w-0 basis-0"
                                           label="年份"
                                           label-class="font-medium sr-only"
                                           placeholder="年份"
                                           :items="availableYears"
                                           :disabled="flatMonthLevelList.length === 0 || !values.programmeOfInterestKey">
                            </GenericSelect>

                            <GenericSelect :key="values.programmeOfInterestKey"
                                           name="enrolmentMonthKey"
                                           class="flex-grow min-w-0 basis-0"
                                           label="月份"
                                           label-class="font-medium sr-only"
                                           placeholder="月份"
                                           :items="availableMonths"
                                           item-value="key"
                                           item-text="name"
                                           :disabled="availableMonths.length === 0 || !values.programmeOfInterestKey">
                            </GenericSelect>
                        </div>
                    </div>
                </div>

                <TextField
                    name="enquiry"
                    class=""
                    label-class="font-medium"
                    placeholder="请输入您的问题"
                    as="textarea"
                    label="您还有其他需要咨询的问题吗？"
                    :maxlength="1000"
                ></TextField>

                <GenericCheckbox
                    value="true"
                    input-class="w-5 h-5 mt-0 cursor-pointer"
                    class="my-8"
                    name="hasAcceptedTermsAndConditions">
                    <template #label>
                        <label class="body-4" for="chk-has-accepted-terms-and-conditions-true">我同意泰莱的
                            <a href="/media/amxhvw35/泰莱大学网页条件与条款.pdf" target="_blank" class="font-bold underline text-brand-red">条款和条件</a>以及
                            <a href="/media/ysphelvn/泰莱大学网页隐私政策.pdf" target="_blank" class="font-bold underline text-brand-red">隐私政策</a>。</label>
                    </template>
                </GenericCheckbox>

                <div class="flex justify-end">
                    <slot name="submit-btn">
                        <GenericButton as="button" type="submit" theme="submit">
                            提交表格
                        </GenericButton>
                    </slot>
                </div>

            </form>
        </div>
    </div>
    <SpinnerLoader v-if="isBusy"></SpinnerLoader>
</template>

<script lang="ts" setup>
import { useForm } from 'vee-validate';
import { GenericCheckbox, GenericSelect, TextField } from '../form';
import { object, string, boolean } from 'yup';
import { computed, ref, watch } from 'vue';
import GenericButton from '../ui/generic-button.vue';
import { useInterestProgrammeStore } from '../../stores';
import { storeToRefs } from 'pinia';
import { SpinnerLoader } from '../ui';
import { VariantProps, cva } from 'class-variance-authority';
import { InterestFormFields } from './types';
import endpoints from '../../endpoints';
import axios from 'axios';
import { useErrorHandling } from '@/modules/core/composables';
import appConstants from '../../app.constants';
import { useUrlSearchParams } from '@vueuse/core';
import dayjs from 'dayjs';

interface Props {
    theme?: VariantProps<typeof variants>["theme"];
};

const interestProgrammeStore = useInterestProgrammeStore();
const { flatEducationLevelList, flatFacultyLevelList, flatMonthLevelList, twoYearFromNow, flatProgrammeList, provinceList, filters, } = storeToRefs(interestProgrammeStore);

defineProps<Props>();

const variants = cva('bg-white w-full p-5 lg:-ml-[10%] lg:p-8 border rounded-[20px] rounded-br-[60px] lg:rounded-[40px] lg:rounded-br-xl z-10', {
    variants: {
        theme: {
            default: '',
            images: 'mt-[-40%] lg:mt-0',
        },
    },
    defaultVariants: {
        theme: 'default'
    }
})

const isBusy = ref(false);

const params = useUrlSearchParams('history');

const {
    handleSubmit,
    resetForm,
    values,
    setFieldValue,
    resetField
} = useForm<InterestFormFields>({
    initialValues: {
        // enquiry: 'test@thebitlab.co enquiry',
        // name: 'Test 1 test@thebitlab.co',
        // contactNumber: '+8613910998888',
        // emailAddress: 'test@thebitlab.co',
        // educationLevelKey: '',
        // facultyKey: '',
        // programmeOfInterestKey: '',
        // enrolmentMonthKey: 2,
        // enrolmentYear: 2023,
        // stateProvinceKey: '',
        // hasAcceptedTermsAndConditions: false,
    },
    validationSchema: object({
        enquiry: string().label('让我们更了解您的问题或疑虑吧!'),
        name: string().required().label('姓名'),
        contactNumber: string().required().matches(/^[\d+]+$/).label('联系电话'),
        emailAddress: string().email().required().matches(appConstants.REGEX_EMAIL).label('邮箱'),
        educationLevelKey: string().required().label('咨询学位类别'),
        facultyKey: string().required().label('咨询院系'),
        programmeOfInterestKey: string().required().label('咨询专业'),
        enrolmentMonthKey: string().required().label('入学月份'),
        enrolmentYear: string().required().label('入学年份'),
        stateProvinceKey: string().required().label('所在省份'),
        hasAcceptedTermsAndConditions: boolean().required().label('条款和条件以及隐私政策'),
    })
})

const onSubmit = handleSubmit(
    async values => {

        handleFormSubmit(values);
    },
    (error) => console.log(error)
)

// const availableMonths = computed(() => {
//     const selectedYear = values.enrolmentYear;
//     const programmeIntakeMonths = flatMonthLevelList.value;

//     const thisYear = new Date().getFullYear();

//     if (selectedYear > thisYear) {
//         return programmeIntakeMonths;
//     }

//     return programmeIntakeMonths.filter(x => {
//         const intakeDate = new Date(selectedYear, x.month, 1);
//         return dayjs(intakeDate).isAfter(dayjs().add(1, 'month'), 'month');
//     })
// })

const getAvailableMonths = (selectedYear: number) => {
    const programmeIntakeMonths = flatMonthLevelList.value;
    const thisYear = new Date().getFullYear();

    if (selectedYear > thisYear) {
        return programmeIntakeMonths;
    }

    return programmeIntakeMonths.filter(x => {
        const intakeDate = new Date(selectedYear, x.month, 1);
        return dayjs(intakeDate).isAfter(dayjs().add(1, 'month'), 'month');
    })
}

const availableMonths = computed(() => getAvailableMonths(values.enrolmentYear));

const availableYears = computed(() => twoYearFromNow.value.filter(x => getAvailableMonths(x).length > 0));

// This will clear off the selected month if it is no longer available for selection
watch(() => values.enrolmentYear, () => {
    if (!values.enrolmentMonthKey) return;

    // const availableMonths = getAvailableMonths(selectedYear);
    if (!availableMonths.value.map(x => x.key).includes(values.enrolmentMonthKey)) {
        resetField('enrolmentMonthKey', { touched: false, errors: [], value: undefined })
    }
})

const handleFormSubmit = async (formValues: InterestFormFields) => {
    if (!formValues) return;
    isBusy.value = true;

    console.log('calling register api', formValues);

    const formData = {
        Name: formValues.name,
        ContactNumber: formValues.contactNumber,
        EmailAddress: formValues.emailAddress,
        StateProvinceKey: formValues.stateProvinceKey,
        EducationLevelKey: formValues.educationLevelKey,
        FacultyKey: formValues.facultyKey,
        ProgrammeOfInterestKey: formValues.programmeOfInterestKey,
        EnrolmentMonthKey: formValues.enrolmentMonthKey,
        EnrolmentYear: formValues.enrolmentYear,
        HasAcceptedTermsAndConditions: Boolean(formValues.hasAcceptedTermsAndConditions),
        Enquiry: formValues.enquiry,
    }

    const {
        utm_source,
        utm_medium,
        utm_campaign,
        utm_term,
        utm_content,
    } = getUtmParams();

    let rawBody: Record<string, any> = {
        ...formData,
        UtmSource: utm_source || '',
        UtmMedium: utm_medium || '',
        UtmCampaign: utm_campaign || '',
        UtmTerm: utm_term || '',
        UtmContent: utm_content || '',

        FirstTouchLeadSourceDetail: utm_source || 'China Website',
        FirstTouchLeadSourceChannel: determineTrafficSource(utm_source, utm_medium),
        OnlineSource: checkReferrer(),  // ??
    }

    try {
        const formData = new FormData();
        for (const [key, value] of Object.entries(rawBody)) {
            formData.append(key, value);
        }

        const request = axios.post(
            endpoints.INTEREST_FORM,
            formData
        )

        const response = await useErrorHandling(request);

        console.log('calling register api success', response.data.data);

        window.location.assign('/thank-you/');
    } catch (error: any) {
        window.location.assign('/error/');
        throw error
    } finally {
        isBusy.value = false;
        resetForm();
    }
}

const getUtmParams = (): {
    utm_source?: string;
    utm_medium?: string;
    utm_campaign?: string;
    utm_term?: string;
    utm_content?: string;
} => {

    const { utm_source, utm_medium, utm_campaign, utm_term, utm_content } = params;

    return {
        utm_source: typeof utm_source === 'string' ? utm_source : undefined,
        utm_medium: typeof utm_medium === 'string' ? utm_medium : undefined,
        utm_campaign: typeof utm_campaign === 'string' ? utm_campaign : undefined,
        utm_term: typeof utm_term === 'string' ? utm_term : undefined,
        utm_content: typeof utm_content === 'string' ? utm_content : undefined,
    };
};

const checkReferrer = () => {
    var referrer = document.referrer;

    var chineseSocialMediaDomains = [
        "wechat.com",
        "weibo.com",
        "qq.com",
        "douyin.com",
        "youku.com",
        "tieba.baidu.com",
        "meituan.com",
        "kuaishou.com",
        "zhihu.com",
        "linkedin.com", // Note: LinkedIn operates in China
        // Add more Chinese social media domains as needed
    ];

    const foundDomain = chineseSocialMediaDomains.find(domain => referrer.includes(domain));
    return foundDomain ? 'Lead Ads Social Media' : 'Lead Generation Page';
}

const determineTrafficSource = (utmSource = '', utmMedium = '') => {
    const organicSources = ["baidu", "sogou", "360_search", "bing_china", "bing"];
    const socialMediaOrganicSources = ["weixin", "weibo", "xiaohongshu", "kuaishou", "douyin", "qq", "zhihu", "youku", "bilibili"];
    const paidAdvertisingSources = [
        "baidu_advertising", "tencent_advertising", "alibaba_advertising",
        "weibo_advertising", "douyin_advertising", "xiaohongshu_advertising",
        "kuaishou_advertising", "360_advertising", "sogou_advertising",
        "zhihu_advertising", "douban_advertising"
    ];

    const isDirect = utmSource === '';
    const isOrganic = organicSources.includes(utmSource) && !['PAID', 'paid'].includes(utmMedium);
    const isPaidAdvertising = paidAdvertisingSources.includes(utmSource) && ['cpl', 'cpc', 'sem'].includes(utmMedium);
    const isSocialMediaOrganic = socialMediaOrganicSources.includes(utmSource) && ['org_social'].includes(utmMedium);
    const isSocialMediaPaid = socialMediaOrganicSources.includes(utmSource) && ['paid_social'].includes(utmMedium);

    switch (true) {
        case isDirect:
            return "Direct";
        case isOrganic:
            return "Search Engine (Organic)";
        case isPaidAdvertising:
            return "Paid Advertising";
        case isSocialMediaOrganic:
            return "Social Media (Organic)";
        case isSocialMediaPaid:
            return "Social Media (Paid)";
        default:
            return "Others";
    }
}

// console.log('----------------------------------------------');
// console.log('------------------- Direct ---------------------');
// console.log('----------------------------------------------');

// const utmSourceE1 = "";
// const utmSourceEM1 = "";
// const utmSourceE2 = "baidu";
// const utmSourceEM2 = "";
// const utmSourceE3 = "baidu";
// const utmSourceEM3 = "paid";

// console.log(`${utmSourceE1} | ${utmSourceEM1} === `, determineTrafficSource(utmSourceE1, utmSourceEM1));  // Output: "Direct"
// console.log(`${utmSourceE2} | ${utmSourceEM2} === `, determineTrafficSource(utmSourceE2, utmSourceEM2));  // Output: "NOT Direct"
// console.log(`${utmSourceE3} | ${utmSourceEM3} === `, determineTrafficSource(utmSourceE3, utmSourceEM3));  // Output: "NOT Direct"

// console.log('----------------------------------------------');
// console.log('-------------------Search Engine (Organic)---------------------');
// console.log('----------------------------------------------');

// const utmSourceA1 = "baidu";
// const utmSourceA2 = "bing_china";
// const utmSourceA3 = "360_search";
// const utmSourceAM1 = "";
// const utmSourceAM2 = "";
// const utmSourceAM3 = "";

// const utmSourceA4 = "sogou";
// const utmSourceA5 = "baidu";
// const utmSourceA6 = "baidu";
// const utmSourceAM4 = "paid";
// const utmSourceAM5 = "paid";
// const utmSourceAM6 = "PAID";

// console.log(`${utmSourceA1} | ${utmSourceAM1} === `, determineTrafficSource(utmSourceA1, utmSourceAM1));  // Output: "Search Engine (Organic)"
// console.log(`${utmSourceA2} | ${utmSourceAM2} === `, determineTrafficSource(utmSourceA2, utmSourceAM2));  // Output: "Search Engine (Organic)"
// console.log(`${utmSourceA3} | ${utmSourceAM3} === `, determineTrafficSource(utmSourceA2, utmSourceAM3));  // Output: "Search Engine (Organic)"
// console.log(`${utmSourceA3} | ${utmSourceAM4} === `, determineTrafficSource(utmSourceA3, utmSourceAM4));  // Output: "NOT Social Media (Organic)"
// console.log(`${utmSourceA4} | ${utmSourceAM5} === `, determineTrafficSource(utmSourceA4, utmSourceAM5));  // Output: "NOT Social Media (Organic)"
// console.log(`${utmSourceA5} | ${utmSourceAM6} === `, determineTrafficSource(utmSourceA5, utmSourceAM6));  // Output: "NOT Social Media (Organic)"

// console.log('----------------------------------------------');
// console.log('-------------------Paid Advertising---------------------');
// console.log('----------------------------------------------');

// const utmSourceT1 = "baidu_advertising";
// const utmSourceT2 = "kuaishou_advertising";
// const utmSourceT3 = "360_advertising";
// const utmSourceTM1 = "cpl";
// const utmSourceTM2 = "cpc";
// const utmSourceTM3 = "sem";

// const utmSourceT4 = "baidu_advertising";
// const utmSourceT5 = "kuaishou_advertising";
// const utmSourceT6 = "360_advertising";
// const utmSourceTM4 = "";
// const utmSourceTM5 = "";
// const utmSourceTM6 = "";

// console.log(`${utmSourceT1} | ${utmSourceTM1} === `, determineTrafficSource(utmSourceT1, utmSourceTM1));   // Output: "Paid Advertising"
// console.log(`${utmSourceT2} | ${utmSourceTM2} === `, determineTrafficSource(utmSourceT2, utmSourceTM2));   // Output: "Paid Advertising"
// console.log(`${utmSourceT3} | ${utmSourceTM3} === `, determineTrafficSource(utmSourceT3, utmSourceTM3));   // Output: "Paid Advertising"
// console.log(`${utmSourceT4} | ${utmSourceTM4} === `, determineTrafficSource(utmSourceT4, utmSourceTM4));   // Output: "NOT Paid Advertising"
// console.log(`${utmSourceT5} | ${utmSourceTM5} === `, determineTrafficSource(utmSourceT5, utmSourceTM5));   // Output: "NOT Paid Advertising"
// console.log(`${utmSourceT6} | ${utmSourceTM6} === `, determineTrafficSource(utmSourceT6, utmSourceTM6));   // Output: "NOT Paid Advertising"

// console.log('----------------------------------------------');
// console.log('----------------------------------------------');
// console.log('----------------------------------------------');

// const utmSourceB1 = "weixin";
// const utmSourceB2 = "zhihu";
// const utmSourceB3 = "bilibili";
// const utmSourceBM1 = "org_social";
// const utmSourceBM2 = "org_social";
// const utmSourceBM3 = "org_social";

// const utmSourceB4 = "weixin";
// const utmSourceB5 = "zhihu";
// const utmSourceB6 = "bilibili";
// const utmSourceBM4 = "";
// const utmSourceBM5 = "";
// const utmSourceBM6 = "";

// console.log(`${utmSourceB1} | ${utmSourceBM1} === `, determineTrafficSource(utmSourceB1, utmSourceBM1)); // Output: "Social Media (Organic)"
// console.log(`${utmSourceB2} | ${utmSourceBM2} === `, determineTrafficSource(utmSourceB2, utmSourceBM2)); // Output: "Social Media (Organic)"
// console.log(`${utmSourceB3} | ${utmSourceBM3} === `, determineTrafficSource(utmSourceB3, utmSourceBM3)); // Output: "Social Media (Organic)"
// console.log(`${utmSourceB4} | ${utmSourceBM4} === `, determineTrafficSource(utmSourceB4, utmSourceBM4)); // Output: "NOT Social Media (Organic)"
// console.log(`${utmSourceB5} | ${utmSourceBM5} === `, determineTrafficSource(utmSourceB5, utmSourceBM5)); // Output: "NOT Social Media (Organic)"
// console.log(`${utmSourceB6} | ${utmSourceBM6} === `, determineTrafficSource(utmSourceB6, utmSourceBM6)); // Output: "NOT Social Media (Organic)"

// console.log('----------------------------------------------');
// console.log('----------------Social Media (Organic)-----------------');
// console.log('----------------------------------------------');

// const utmSourceP1 = "weixin";
// const utmSourceP2 = "zhihu";
// const utmSourceP3 = "bilibili";
// const utmSourcePM1 = "paid_social";
// const utmSourcePM2 = "paid_social";
// const utmSourcePM3 = "paid_social";

// const utmSourceP4 = "weixin";
// const utmSourceP5 = "zhihu";
// const utmSourceP6 = "bilibili";
// const utmSourcePM4 = "";
// const utmSourcePM5 = "";
// const utmSourcePM6 = "";

// console.log(`${utmSourceP1} | ${utmSourcePM1} === `, determineTrafficSource(utmSourceP1, utmSourcePM1));  // Output: "Social Media (Paid)"
// console.log(`${utmSourceP2} | ${utmSourcePM2} === `, determineTrafficSource(utmSourceP2, utmSourcePM2));  // Output: "Social Media (Paid)"
// console.log(`${utmSourceP3} | ${utmSourcePM3} === `, determineTrafficSource(utmSourceP3, utmSourcePM3));  // Output: "Social Media (Paid)"
// console.log(`${utmSourceP4} | ${utmSourcePM4} === `, determineTrafficSource(utmSourceP4, utmSourcePM4));  // Output: "NOT Social Media (Paid)"
// console.log(`${utmSourceP5} | ${utmSourcePM5} === `, determineTrafficSource(utmSourceP5, utmSourcePM5));  // Output: "NOT Social Media (Paid)"
// console.log(`${utmSourceP6} | ${utmSourcePM6} === `, determineTrafficSource(utmSourceP6, utmSourcePM6));  // Output: "NOT Social Media (Paid)"

// console.log('----------------------------------------------');
// console.log('----------------------------------------------');



const educationLevelKeyChanged = () => {
    filters.value.educationLevelKey = values.educationLevelKey;
}

const facultyKeyChanged = () => {
    filters.value.facultyKey = values.facultyKey;
}

const programmeOfInterestKeyChanged = () => {
    filters.value.programmeOfInterestKey = values.programmeOfInterestKey;
}

interestProgrammeStore.getAPIList();
</script>
