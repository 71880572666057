<template>
    <form :class="cn('pb-8 pt-4 sticky lg:relative bg-white top-[calc(var(--nav-height))] lg:top-0 z-10 transition duration-500 delay-300 ease-in-out', {
        'top-0': lastDirections.bottom
    })">
        <div class="hidden lg:flex flex-col mb-12 pt-8">
            <div class="flex justify-between items-center pb-3">
                <label class="body-2 text-start">{{ selectLabel }}</label>
                <button
                    @click="removeAllFilters"
                    class="text-[16px] lg:text-[18px] text-brand-red font-bold underline hover:text-black disabled:text-brand-gray-200 disabled:select-none transition duration-200 ease-in-out"
                    :disabled="totalFilterSelected === 0">
                    {{  clearAllFilterLabel }} ({{ totalFilterSelected }})
                </button>
            </div>

            <PillRadioGroup
                :items="educationLevelList"
                item-text="label"
                item-value="value"
                name="educationLevelId"
                class="w-full"
                option-class="flex h-full w-full"
                group-options-class="grid grid-cols-2 xl:grid-cols-5 gap-3 [&>*:first-child]:col-span-2 xl:[&>*:first-child]:col-span-1 space-x-0"
                inner-option-class=""
                @input-value-changed="educationLevelIdChanged">
                <template #radio-group-label="{ item, checked, index }">
                    <p class="flex justify-center items-center gap-2 text-[18px] text-center font-bold line-clamp-2">
                        <Icon :key="index" symbol="arrow-right" class="w-3 h-3 text-brand-red transition duration-500 ease-in-out hidden" :class="{ 'block' : checked }"></Icon>
                        {{ item.label }}
                    </p>
                </template>
            </PillRadioGroup>
        </div>

        <div class="flex justify-between gap-4 lg:gap-8 items-center">

            <PopoverGroup class="hidden lg:flex gap-5">
                <ProgrammeIntakeMutipleSelectionInput
                    :label="facultyFilterLabel"
                    :placeholder="facultyFilterLabel"
                    name="facultyIds"
                    :items="facultiesList"
                    item-text="name"
                    item-value="key"
                    @input-value-changed="facultyIdsChanged"
                ></ProgrammeIntakeMutipleSelectionInput>

                <ProgrammeIntakeMutipleSelectionInput
                    :label="intakeFilterLabel"
                    :placeholder="intakeFilterLabel"
                    name="intakeMonthIds"
                    :items="intakeMonthsList"
                    item-text="name"
                    item-value="key"
                    @input-value-changed="intakeMonthIdsChanged"
                ></ProgrammeIntakeMutipleSelectionInput>

            </PopoverGroup>

            <ProgrammeIntakeFormSearch
                name="searchTerms"
                @input-value-changed="searchTermsChanged"
            ></ProgrammeIntakeFormSearch>

            <div class="block lg:hidden">
                <ProgrammeIntakeOffcanvas>
                    <template #activator="{ show }">
                        <button class="text-[18px] lg:text-[20px] font-bold text-brand-gold whitespace-nowrap" type="button" @click="show">
                            {{ filterLabel }} ({{ totalFilterSelected }})
                        </button>
                    </template>
                    <template #="{ hide }">
                        <div class="container h-full overflow-y-auto">
                            <div class="h-[--nav-height] flex justify-end items-center">
                                <button type="button" @click="hide">
                                    <Icon symbol="x" class="w-[30px] h-[30px] text-black"></Icon>
                                </button>
                            </div>

                            <div class="flex justify-between items-center pb-3">
                                <label class="body-2 text-start font-bold">{{ selectLabel }}</label>
                                <button
                                    @click="removeAllFilters"
                                    class="text-[16px] lg:text-[18px] text-brand-red font-bold underline hover:text-black disabled:text-brand-gray-200 disabled:select-none transition duration-200 ease-in-out"
                                    :disabled="totalFilterSelected === 0">
                                    {{ clearAllFilterLabel }} ({{ totalFilterSelected }})
                                </button>
                            </div>

                            <PillRadioGroup
                                :items="educationLevelList"
                                item-text="label"
                                item-value="value"
                                name="educationLevelId"
                                class="w-full"
                                option-class="flex h-full w-full"
                                group-options-class="grid grid-cols-2 xl:grid-cols-5 gap-3 [&>*:first-child]:col-span-2 xl:[&>*:first-child]:col-span-1 space-x-0"
                                inner-option-class=""
                                @input-value-changed="educationLevelIdChanged">
                                <template #radio-group-label="{ item, checked, index }">
                                    <p class="flex justify-center items-center gap-2 text-[14px] text-center font-bold whitespace-nowrap">
                                        <Icon :key="index" symbol="arrow-right" class="w-3 h-3 text-brand-red hidden transition duration-500 ease-in-out" :class="{ 'block' : checked }"></Icon>
                                        {{ item.label }}
                                    </p>
                                </template>
                            </PillRadioGroup>

                            <ProgrammeIntakeMutipleSelectionInput
                                :label="facultyFilterLabel"
                                :placeholder="facultyFilterLabel"
                                name="facultyIds"
                                :items="facultiesList"
                                @input-value-changed="facultyIdsChanged"
                            ></ProgrammeIntakeMutipleSelectionInput>

                            <ProgrammeIntakeMutipleSelectionInput
                                :label="intakeFilterLabel"
                                :placeholder="intakeFilterLabel"
                                name="intakeMonthIds"
                                :items="intakeMonthsList"
                                @input-value-changed="intakeMonthIdsChanged"
                            ></ProgrammeIntakeMutipleSelectionInput>

                        </div>
                    </template>
                </ProgrammeIntakeOffcanvas>
            </div>
        </div>
    </form>
</template>

<script setup lang="ts">
import { Icon } from '@/modules/core/components';
import { PillRadioGroup } from '../form';
import { useForm } from 'vee-validate';
import ProgrammeIntakeMutipleSelectionInput from './programme-intake-mutiple-selection-input.vue';
import ProgrammeIntakeOffcanvas from './programme-intake-offcanvas.vue';
import { useIntakeStore } from '../../stores';
import { storeToRefs } from 'pinia';
import { computed, onMounted, ref, watch } from 'vue';
import ProgrammeIntakeFormSearch from './programme-intake-form-search.vue';
import { useDebounce, useScroll, useUrlSearchParams } from '@vueuse/core';
import { cn } from '@/modules/core/utilities';
import { PopoverGroup } from '@headlessui/vue';

interface Props {
    selectLabel: string;
    filterLabel: string;
    clearAllFilterLabel: string;
    facultyFilterLabel: string;
    intakeFilterLabel: string;
}

defineProps<Props>();

const emits = defineEmits(['search-terms-changed']);

const totalFilterSelected = computed(() => {
    if (!filters.value.facultyIds.length && !filters.value.intakeMonthIds.length) return 0;
    return filters.value.facultyIds.length + filters.value.intakeMonthIds.length;
})

const intakeStore = useIntakeStore();
const { filters, isBusy, educationLevelList, facultiesList, intakeMonthsList } = storeToRefs(intakeStore);

const params = useUrlSearchParams('history');

const lastDirections = ref({ top: false, bottom: false });
const { y, directions } = useScroll(
    window,
    {
        onScroll: () => {
            lastDirections.value.top = directions?.top;
            lastDirections.value.bottom = directions?.bottom;
        }
    }
);

const searchTermValue = ref<string>();
const debouncedSearchTermValue = useDebounce(searchTermValue, 400);

const {
    resetForm,
    setValues,
} = useForm({});

const searchTermsChanged = (newSearchTerm: string) => {
    isBusy.value = true;
    searchTermValue.value = newSearchTerm;
    emits('search-terms-changed');
}

watch(debouncedSearchTermValue, (newSearchTerm) => {
    isBusy.value = false;
    filters.value.searchTerms = newSearchTerm || '';

    updateRoute();
});

const educationLevelIdChanged = (value: string | number | Array<string | number>) => {
    // console.log('Radio group value changed:', value);
    filters.value.educationLevelId = value as string;
    filters.value.currentPageIndex = 0;

    updateRoute();
}

const facultyIdsChanged = (value: Array<string>) => {
    // console.log('Radio group value changed:', value);
    filters.value.facultyIds = value;

    updateRoute();
}

const intakeMonthIdsChanged = (value: Array<string | number>) => {
    // console.log('Radio group value changed:', value);
    filters.value.intakeMonthIds = value;

    updateRoute();
}

const removeAllFilters = () => {
    setValues({
        facultyIds: [],
        intakeMonthIds: [],
    });

    facultyIdsChanged([]);
    intakeMonthIdsChanged([]);
}

const updateRoute = () => {
    params.searchTerms = filters.value.searchTerms;
    params.educationLevelId = filters.value.educationLevelId;
    params.facultyIds = filters.value.facultyIds;
    params.intakeMonthIds = filters.value.intakeMonthIds as string[] | string;
};

const initializeFiltersFromQuery = () => {
    const { searchTerms, educationLevelId, facultyIds, intakeMonthIds } = params;

    if (searchTerms) {
        filters.value.searchTerms = searchTerms as string;
    }

    if (educationLevelId) {
        filters.value.educationLevelId = educationLevelId as string;
    }

    if (facultyIds) {
        filters.value.facultyIds = Array.isArray(facultyIds) ? [...facultyIds] : [facultyIds];
    }

    if (intakeMonthIds) {
        filters.value.intakeMonthIds = Array.isArray(intakeMonthIds) ? [...intakeMonthIds] : [intakeMonthIds];
    }

    resetForm({
        values: {
            searchTerms: filters.value.searchTerms,
            educationLevelId: filters.value.educationLevelId,
            facultyIds: filters.value.facultyIds,
            intakeMonthIds: filters.value.intakeMonthIds,
        }
    });
};

onMounted(() => {
    initializeFiltersFromQuery();
})

</script>
