<template>
    <div>
        <slot v-if="isSupported" :share="share"></slot>

        <GenericModal
            v-else
            modal-panel-background-class="bg-white rounded-sm"
            modal-title="Share">
            <template #activator="{ show }">
                <slot :share="show"></slot>
            </template>

            <template #modal-header="{ hide }">
                <div class="flex items-center justify-between w-full px-5 py-4 md:py-8 md:px-8">
                    <DialogTitle class="heading-3 lg:heading-4 font-bold text-center lg:text-start">
                        分享链接
                    </DialogTitle>
                    <button
                        ref="fallbackInitialFocusElRef1"
                        type="button"
                        @click="hide"
                        class="text-brand-darkBlue focus:outline-none">
                        <span class="sr-only">
                            Close modal
                        </span>
                        <Icon symbol="x" class="w-7 h-7 text-white" />
                    </button>
                </div>
            </template>

            <div class="[&::after]:bg-black pixel-border bg-white mb-6 pt-3 pb-2">
                <TextField
                    ref="inputRef"
                    mandatory
                    readonly
                    name="shareValue"
                    :value="shareValue"
                ></TextField>
            </div>

            <GenericButton
                @click="onCopyLink"
                as="button"
                type="button"
                theme="submit">
                {{ buttonText }}
            </GenericButton>

        </GenericModal>
    </div>

</template>

<script setup lang="ts">
import { GenericModal, Icon } from '@/modules/core/components';
import { DialogTitle } from '@headlessui/vue';
import { refAutoReset, useClipboard, useShare } from '@vueuse/core';
import { ref } from 'vue';
import { GenericButton } from '../ui';
import { TextField } from '../form';
import { getMetaContentByName } from '@/modules/core/utilities';

const sharingContent = {
    url: location.href,
    title: document.title,
    text: getMetaContentByName('description') ?? undefined,
}

const { isSupported, share: nativeShare } = useShare({
    title: sharingContent.title,
    text: sharingContent.text,
    url: sharingContent.url
});

const share = () => {
    if (isSupported.value) {
        return nativeShare()
    }
};

const clipboard = useClipboard()
const shareValue = ref(sharingContent.url);

const buttonText = refAutoReset('复制链接', 1000);

const onCopyLink = () => {
    clipboard.copy(shareValue.value)
    buttonText.value = '链接已复制'
}

</script>

<style scoped></style>
