import { PropType } from "vue";

type SupportedInputTypes = "email" | "hidden" | "number" | "password" | "search" | "tel" | "text" | "url";

const commonFieldProps = {
    id: String,
    label: String,
    autocomplete: String,
    placeholder: String,
    type: {
        type: String as PropType<SupportedInputTypes>,
        default: "text"
    },
    value: String,
    disabled: Boolean,
    readonly: Boolean,
    maxlength: Number,
    required: Boolean,
    mandatory: Boolean,
    hint: String,
    fieldClass: String,
    labelClass: String,
    iconName: String,
    iconPosition: {
        type: String as PropType<'left' | 'right'>,
        default: 'left'
    },
    iconClass: String,
    showArrow: Boolean,
    as: {
        type: String as PropType<'textarea'|'input'>,
        default: 'input'
    }
}

export default commonFieldProps;