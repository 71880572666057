<template>
    <div class="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 lg:gap-5 pb-12">
        <TransitionGroup
            enter-active-class="ease-out duration-300"
            leave-active-class="ease-in"
            enter-from-class="opacity-0 invisible -translate-y-2"
            enter-to-class="visible opacity-1 translate-y-0"
            leave-from-class="visible opacity-1"
            leave-to-class="invisible opacity-0">
            <article v-for="item in paginateItems" :key="item.id" class="flex flex-col md:p-0 h-full border-0 select-none">
                <a :href="completeUrl(item.url)">
                    <transition 
                        enter-active-class="transition duration-200 ease-[cubic-bezier(.4,0,.2,1)]"
                        enter-from-class="-translate-y-1 opacity-0"
                        enter-to-class="translate-y-0 opacity-100"
                        leave-active-class="transition duration-200 ease-[cubic-bezier(.4,0,.2,1)]"
                        leave-from-class="translate-y-0 opacity-100"
                        leave-to-class="-translate-y-1 opacity-0">
                        <div>
                            <div class="block overflow-hidden aspect-w-1 aspect-h-1">
                                <img
                                    v-if="item.coverMedia && item.coverMedia?.url" 
                                    :src="item.coverMedia?.url"
                                    class="w-full border rounded-[25px] rounded-br-[40px] lg:rounded-br-lg !rounded-bl-[0px] object-cover cover object-[--object-position]"
                                    style="object-position: center"
                                    alt="imagealt"
                                    loading="lazy">
                                <img 
                                    v-else 
                                    class="w-full h-full bg-gray-300 rounded-[25px] rounded-br-[40px] lg:rounded-br-lg !rounded-bl-[0px] object-cover cover object-[--object-position]">
                            </div>
                            <div class="relative pt-4 lg:pt-6 text-white">
                                <div>
                                    <h3 v-for="(tag, index) in item.tags" :key="index" class="inline-flex px-3 py-1 rounded-full text-[12px] lg:text-[14px] font-bold bg-brand-gold mb-1 mr-2">{{ tag.name }}</h3>
                                </div>
                                <div class="text-black">
                                    <span class="text-brand-purple text-[18px] lg:text-[20px] font-bold mb-2 line-clamp-3">{{ item.title }}</span>
                                </div>     
                            </div>
                        </div>
                    </transition>
                </a>
            </article>
        </TransitionGroup>
    </div>
    <div>
        <Pagination
            :page-number="filters.pageNumber"
            :pages-per-view="paginationData.pageSize"
            :total-pages="paginationData.totalPages"
            @page-change="filters.pageNumber = $event"
        ></Pagination>
    </div>
</template>

<script setup lang="ts">
import { Pagination } from '@/modules/core/components';
import { storeToRefs } from 'pinia';
import { useNewsAndEventsStore } from '../../stores/news-events';
import { computed, TransitionGroup } from 'vue';

const PAGE_SIZE = 12;

const newsEventsStore = useNewsAndEventsStore();
const { newsArticlesList, filters, filteredItems } = storeToRefs(newsEventsStore);

const completeUrl = (url: string) => {
    return `${window.location.origin}${url}`;
};

const paginationData = computed(() => {
    return {
        totalItems: newsArticlesList.value.length,
        totalPages: Math.ceil(filteredItems.value.length / PAGE_SIZE),
        pageSize: PAGE_SIZE,
    }
})

const paginateItems = computed(() => {
    const startIndex = (filters.value.pageNumber - 1) * paginationData.value.pageSize;
    const endIndex = startIndex + paginationData.value.pageSize;
    return filteredItems.value.slice(startIndex, endIndex);
})

newsEventsStore.getAPIList();

</script>

<style scoped>

</style>