<template>
    <select
        ref="genericSelectEl"
        :class="cn(
            variants({
                intent: formFloating ? 'formFloating' : 'default',
                state: fieldState,
                iconPosition: iconName && iconPosition ? iconPosition : undefined
            }),
            props.class
        )"
        :value="value"
    >
        <slot></slot>
    </select>
</template>

<script lang="ts" setup>
import { cn } from '@/modules/core/utilities';
import { cva } from 'class-variance-authority';
import { computed, ref } from 'vue';

interface Props {
    class?: string;
    placeholder?: string;
    errorMessage?: string;
    iconName?: string;
    showArrow?: boolean;
    iconPosition?: 'left'|'right';
    formFloating?: boolean;
    value?: any;
}

const props = withDefaults(defineProps<Props>(), {
    iconPosition: 'left'
})

const variants = cva('generic-input block  w-full bg-white bg-none body-2 border-0 border-b  border-brand-gray-100 placeholder:text-brand-gray-200  focus:ring-0 focus:border-brand-gold disabled:opacity-50 disabled:cursor-not-allowed disabled:bg-gray-100 transition', {
    variants: {
        intent: {
            default: 'pt-4 pb-2 px-0',
            formFloating: 'pb-2 px-4 pt-6 sm:pt-7 peer h-[55px] md:h-[60px]',
        },
        state: {
            invalid: 'border-red-500 focus:ring-red-500 focus:border-red-500',
            empty: 'text-brand-gray-200',
            default: 'text-black'
        },
        iconPosition: {
            left: 'ps-12',
            right: 'pe-12'
        },
    },
    compoundVariants: [
        {
            intent: 'formFloating',
            state: ['empty', 'invalid'],
            class: 'text-transparent'
        }
    ],
    defaultVariants: {}
})

const genericSelectEl = ref<HTMLSelectElement|null>(null);

const fieldState = computed(() => {
    if (props.errorMessage) return 'invalid';
    if (!props.value) return 'empty';
    return 'default'
})

defineExpose({ genericSelectEl })
</script>
