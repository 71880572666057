<template>
    <transition v-bind="$attrs" :css="false" @before-enter="onBeforeEnter" @enter="enter">
        <div class="text-transition">
            <slot></slot>
        </div>
    </transition>
</template>

<script lang="ts" setup>
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import Splitting from 'splitting';
gsap.registerPlugin(ScrollTrigger);

const props = defineProps({
    delay: {
        type: Number,
        default: 0.3
    }
})

const print = window.matchMedia('print').matches;
const motionOK = !window.matchMedia('(prefers-reduced-motion)').matches || !print;

const onBeforeEnter = (el: Element) => {

    if (!motionOK) return;
    const titles = el.querySelectorAll('h1, h2, h3, h4, h5, h6');

    for (let element of Array.from(titles)) {
        Splitting({ target: element, by: 'words' })
        gsap.set(element.querySelectorAll('.word'), { autoAlpha: 0 })
    }
}

const enter = (el: Element) => {

    if (!motionOK) {
        el.classList.remove('invisible');
        return;
    };

    const listItems = Array.from(el.querySelectorAll<Element>('li'));
    const paragraphs = Array.from(el.querySelectorAll<Element>('p'));

    const titles = Array.from(el.querySelectorAll('h1, h2, h3, h4, h5, h6'));

    if (titles.length > 0) {
        ScrollTrigger.batch(titles, {
            once: true,
            onEnter: batch => {
                batch.forEach(elem => {
                    const words = elem.querySelectorAll('.word');
                    gsap.fromTo(
                        words,
                        {
                            scale: 0.95,
                            autoAlpha: 0,
                            y: 24,
                        },
                        {
                            delay: props.delay,
                            autoAlpha: 1,
                            scale: 1,
                            y: 0,
                            duration: 0.3,
                            ease: 'power2.out',
                            stagger: (index) => 0.1 + (index * 0.01)
                        }
                    )
                })
            }
        })
    }

    ScrollTrigger.batch([...paragraphs, ...listItems], {
        onEnter: batch => {
            batch.forEach(elem => {
                let tweenVars: gsap.TweenVars = {
                    autoAlpha: 0,
                    ease: 'power3.out',
                    duration: .6,
                    stagger: 0.1,
                    delay: props.delay
                };
                if (paragraphs.includes(elem)) {
                    tweenVars.y = 32;
                }
                if (titles.includes(elem)) {
                    tweenVars.scale = 0.9;
                }

                el.classList.remove('invisible');

                gsap.from(elem, tweenVars)
            })
        },
        once: true
    })
}
</script>

<style lang="scss">

.text-transition {
    .word {
        will-change: transform;
        display: inline-block;
    }

    p {
        will-change: transform;
    }
}
</style>
