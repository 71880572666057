<template>
    <picture
        v-if="usePicture"
        ref="rootEl"
        :class="cn(cssClassList, props.class)">
        <slot></slot>
        <img
            v-bind="$attrs"
            :src="srcImage"
            :alt="alt"
            :srcset="srcsetImage"
            :width="width"
            :height="height"
            @load="onLoad"
            @error="onError"
            :class="cn(cssClassList, props.class)"
        >
    </picture>
    <img
        v-else
        ref="rootEl"
        v-bind="$attrs"
        :src="srcImage"
        :alt="alt"
        :srcset="srcsetImage"
        :width="width"
        :height="height"
        @load="onLoad"
        @error="onError"
        :class="cn(cssClassList, props.class)"
    >
</template>

<script lang="ts" setup>
import { cn } from '@/modules/core/utilities';
import { ref, computed, onMounted, onBeforeUnmount } from 'vue';

/**
 * Adapted from https://github.com/alexjoverm/v-lazy-image/blob/master/v-lazy-image/index-v2.js
 */

interface Props {
    src?: string;
    alt?: string;
    width?: string;
    height?: string;
    srcPlaceholder?: string;
    srcset?: string;
    intersectionOptions?: IntersectionObserverInit;
    usePicture?: boolean;
    class?: string;
    fadeInClass?: string;
}

const props = withDefaults(defineProps<Props>(), {
    src: '/images/placeholder.jpg',
    srcPlaceholder: 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7',
    intersectionOptions: () => ({}),
    usePicture: false,
    fadeInClass: 'v-lazy-image-fades-in'
})

const emit = defineEmits<{
    (e: 'intersected'): void,
    (e: 'error', value?: HTMLImageElement|HTMLPictureElement): void,
    (e: 'load', value?: HTMLImageElement|HTMLPictureElement): void,
}>()

let observer: IntersectionObserver;
const intersected = ref(false);
const loaded = ref(false);
const rootEl = ref<HTMLImageElement|HTMLPictureElement>();

const srcImage = computed(() => intersected.value && props.src ? props.src : props.srcPlaceholder);
const srcsetImage = computed(() => intersected.value && props.srcset ? props.srcset : undefined);
const cssClassList = computed(() => {
    const classList = ['v-lazy-image'];
    if (props.fadeInClass) {
        classList.push(props.fadeInClass)
    }

    if (loaded.value) {
        classList.push('v-lazy-image-loaded');
    }

    return classList;
})

const onLoad = () => {
    if (rootEl.value && rootEl.value.getAttribute('src') !== props.srcPlaceholder) {
        loaded.value = true;
        emit('load', rootEl.value);
    }
}

const onError = () => {
    emit('error', rootEl.value);
}

onMounted(() => {
    if (!('IntersectionObserver' in window)) return;
    if (!rootEl.value) return;

    observer = new IntersectionObserver(
        entries => {
            const image = entries[0];
            if (image.isIntersecting) {
                intersected.value = true;
                observer.disconnect();
                emit('intersected');
            }
        },
        props.intersectionOptions
    );

    observer.observe(rootEl.value);
})

onBeforeUnmount(() => {
    observer && observer.disconnect();
})
</script>

<style>

.v-lazy-image-fades-in {
    opacity: 0;
    transition: opacity .6s ease;
}

.v-lazy-image-fades-in.v-lazy-image-loaded {
    opacity: 1;
}

.v-lazy-image-blurs-in {
    filter: blur(8px);
    transition: filter 0.4s ease-out;
}

.v-lazy-image-blurs-in.v-lazy-image-loaded {
    filter: blur(0);
}
</style>
