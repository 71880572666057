import axios from 'axios';
import { defineStore } from 'pinia';
import endpoints from '../endpoints';
import { UmbracoBaseContent } from '@/modules/website/typings';
import { ProgrammeRecords, Programme } from '../components/interest-form/types';

export const useInterestProgrammeStore = defineStore('interestProgramme', {

    state: () => ({
        isBusy: false,
        filters: {
            educationLevelKey: '',
            facultyKey: '',
            programmeOfInterestKey: '',
        },
        provinceList: [] as UmbracoBaseContent[],
        programmeRecords: [] as ProgrammeRecords[],
    }),
    getters: {
        sampleGetter: state => state,
        twoYearFromNow: () => {
            const currentYear = new Date().getFullYear();
            return [currentYear, currentYear + 1];
        },
        flatEducationLevelList: state => {
            return state.programmeRecords.map(item => {
                return {
                    ...item.educationLevel
                }
            })
        },
        flatFacultyLevelList: state => {
            const selectedEducation = state.programmeRecords.find(item => item.educationLevel.key === state.filters.educationLevelKey);
            if (!selectedEducation) return [];

            return selectedEducation.faculties.map(item => {
                return {
                    ...item
                }
            })
        },
        flatProgrammeList: state => {

            const selectedEducation = state.programmeRecords.find(item => item.educationLevel.key === state.filters.educationLevelKey);
            if (!selectedEducation) return [];

            let filteredProgramme = [] as Programme[];

            const selectedFaculty = selectedEducation.programmes.filter(item => item.faculty.key === state.filters.facultyKey);

            if (state.filters.facultyKey) {
                filteredProgramme = selectedFaculty.map(item => {
                    return {
                        ...item.programme
                    }
                });
            }

            return filteredProgramme || [];

        },
        flatMonthLevelList: state => {
            const selectedEducation = state.programmeRecords.find(item => item.educationLevel.key === state.filters.educationLevelKey);

            if (!selectedEducation || state.filters.programmeOfInterestKey === '') return [];

            const selectedProgramme = selectedEducation.programmes.find(item => item.programme.key === state.filters.programmeOfInterestKey);
            if (!selectedProgramme) return [];

            return selectedProgramme?.programme.availableIntakes.map(item => {
                return {
                    ...item
                }
            })

        },
    },
    actions: {
        async getAPIList() {

            try {
                const requestProvinceList = axios.get<UmbracoBaseContent[]>(endpoints.PROVINCE_LIST);
                const requestProgrammeRecords = axios.get<ProgrammeRecords[]>(endpoints.PROGRAMME_RECORDS);

                const [
                    responseProvinceList,
                    responseProgrammeRecords,
                ] = await Promise.all([requestProvinceList, requestProgrammeRecords]);

                this.$patch({
                    provinceList: responseProvinceList.data,
                    programmeRecords: responseProgrammeRecords.data,
                })

            } catch (error) {
                console.error(error);
            }
        }
    }
})
