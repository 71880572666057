import { defineStore } from 'pinia';
import { IntakeList } from '../components/programme-intake/types';
import { ClientBaseContent, UmbracoBaseContent } from '@/modules/website/typings';

export const useIntakeStore = defineStore('intake', {
    state: () => ({
        data: [] as IntakeList[],
        filters: {
            searchTerms: '',
            currentPageIndex: 0,
            itemPerPage: 2,
            educationLevelId: 'all',
            facultyIds: [] as Array<string>,
            intakeMonthIds: [] as Array<string | number>,
        },
        educationLevelList: [] as ClientBaseContent[],
        intakeMonthsList: [] as ClientBaseContent[],
        facultiesList: [] as ClientBaseContent[],
        isBusy: false,
        educationLevelAll: {} as UmbracoBaseContent,
    }),
    getters: {
        sampleGetter: state => state,
        filteredItem: state => {
            let filterList: IntakeList[];

            if (state.filters.educationLevelId !== state.educationLevelAll.key) {
                filterList = state.data?.map((faculty) => ({
                    ...faculty,
                    programmes: faculty.programmes.filter((programme) => programme.educationLevel.key === state.filters.educationLevelId),
                })) || [];
            } else {
                filterList = state.data || [];
            }

            if (state.filters.facultyIds && state.filters.facultyIds.length > 0) {
                filterList = filterList?.filter((major) => state.filters.facultyIds.includes(major.facultyId)) || [];
            } else {
                filterList = filterList || [];
            }

            if (state.filters.intakeMonthIds && state.filters.intakeMonthIds.length > 0) {
                filterList = filterList?.map((major) => ({
                    ...major,
                    programmes: major.programmes.filter((programme) =>
                        programme.availableIntakes.flatMap(i => i.key).some(intake => state.filters.intakeMonthIds.includes(intake))
                    ),
                })) || [];
            } else {
                filterList = filterList || [];
            }

            filterList = filterList.filter(item => item.programmes && item.programmes.length > 0);

            if (state.filters.searchTerms !== '') {
                const searchResults: IntakeList[] = [];

                filterList.forEach(major => {
                    const matchingCourses = major.programmes.filter(programme =>
                        {
                            const searchTermLowerCase = state.filters.searchTerms.toLowerCase();
                            return programme.name.toLowerCase().includes(searchTermLowerCase) || (programme.programmeEnglishName && programme.programmeEnglishName.toLowerCase().includes(searchTermLowerCase))
                        }
                    );

                    if (matchingCourses.length > 0) {
                        searchResults.push({
                            ...major,
                            programmes: matchingCourses,
                        });
                    }
                });

                console.log(searchResults);
                return searchResults;
            }

            return filterList;
        },
    },
    actions: {
    }
})
