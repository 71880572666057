<template>
    <div class="relative">
        
        <h2 class="block lg:hidden heading-2 font-bold text-center py-4">全部动态</h2>
        <NewsEventsListingForm
            :filter-label="filterLabel"
            :clear-all-filter-label="clearAllFilterLabel"
            @search-terms-changed="searchTermsChangedHandler"
        ></NewsEventsListingForm>
        

        <div v-if="filteredItems.length > 0 && !isBusy">
            <NewsEventsListing></NewsEventsListing>
        </div>

        <div v-else-if="!isBusy" class="flex justify-center items-center p-12">
            <p class="body-4 text-brand-gray-200 text-center">{{ resultNotFoundMessage }}</p>
        </div>

    </div>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { useNewsAndEventsStore } from '../../stores/news-events';
import { UmbracoBaseContent } from '../../typings';
import NewsEventsListingForm from './news-events-listing-form.vue';
import NewsEventsListing from './news-events-listing.vue'
import { onMounted, ref } from 'vue';
import { seamlessScrollTo } from '@/modules/core/utilities';
import { NewsEventItem } from './types';

interface Props {
    newsEventsTagsList: UmbracoBaseContent[];
    newsArticlesList: NewsEventItem[];
    filterLabel: string;
    clearAllFilterLabel: string;
    resultNotFoundMessage :string;
    newsEventsAnchor: string;
}

const props = defineProps<Props>();

const newsEventStore = useNewsAndEventsStore();
const {tagIdAll, newsEventsTagsList, filters, filteredItems, isBusy} = storeToRefs(newsEventStore)

const initialLoad = ref(true);

const searchTermsChangedHandler = () => {
    seamlessScrollTo(props.newsEventsAnchor);
}

onMounted(() => {
    setTimeout(() => initialLoad.value = false, 1000);
})

const mapToOptions = (list: UmbracoBaseContent[]) => list.map(item => ({
    value: item.key,
    label: item.name,
}));

const initialProps = () => {
    newsEventsTagsList.value = mapToOptions(props.newsEventsTagsList);

    filters.value.tagId = props.newsEventsTagsList[0].key;
    tagIdAll.value = props.newsEventsTagsList[0];

}

initialProps()

</script>

<style scoped>

</style>