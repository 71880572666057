import { PropType } from "vue";

const commonSelectionFieldProps = {
    id: String,
    label: String,
    placeholder: String,
    modelValue: [Array, String, Number] as PropType<Array<string|number> | string | number>,
    itemText: String,
    itemValue: String,
    itemDisabled: String,
    autocomplete: String,
    errorMessage: String,
    required: Boolean,
    mandatory: Boolean,
    hint: String,
    fieldClass: String,
    labelClass: String,
    iconName: String,
    iconPosition: {
        type: String as PropType<'left' | 'right'>,
        default: 'left'
    },
    iconClass: String,
    showArrow: Boolean,
    disabled: Boolean,
}

export default commonSelectionFieldProps;