<template>
    <div class="relative flex items-center">
        <input
            type="checkbox"
            @change="onChange"
            :id="idAttr"
            :name="name"
            :checked="checked"
            :disabled="disabled"
            :value="value"
            :class="cn(
                inputVariants({
                    style: pill ? 'pill' : 'checkbox',
                    state: errorMessage ? 'invalid' : undefined
                }),
                inputClass,
                'transition ease'
            )"
        >
        <div :class="{ 'ml-3': !pill }">
            <slot name="label" :idAttr="idAttr">
                <label
                    :for="idAttr"
                    :class="cn(
                        'cursor-pointer',
                        labelVariants({
                            theme,
                            style: pill ? 'pill' : 'checkbox',
                            state: checked ? 'checked' : undefined,
                            size
                        })
                    )">
                    <div v-if="pill">
                        <Icon
                            symbol="plus"
                            :class="cn('h-3 w-3 text-brand-orange transition translate-x-0.2 translate-y-2 stroke-2', checked ? 'opacity-0' : 'opacity-100')">
                        </Icon>
                        <Icon
                            symbol="tick"
                            :class="cn('h-4 w-4 text-brand-orange transition rotate-45 -translate-x-1/6 -translate-y-1.5', checked ? 'opacity-100 rotate-0' : 'opacity-0')">
                        </Icon>
                    </div>
                    {{ label }}
                </label>
            </slot>
            <p v-if="description" class="text-sm text-gray-500">
                {{ description }}
            </p>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { cn } from '@/modules/core/utilities';
import { useField } from 'vee-validate';
import { kebabCase } from 'change-case'
import { computed, toRef } from 'vue';
import { cva } from 'class-variance-authority';
import { Icon } from '@/modules/core/components';

const emit = defineEmits(["update:modelValue"]);

interface Props {
    id?: string;
    name: string;
    label?: string;
    description?: string;
    value: any;
    uncheckedValue?: any;
    initialValue?: any;
    valueToString?: (value: any) => string;
    inputClass?: string;
    theme?: 'brand-orange'|'brand-blue'|'brand-tiffanyBlue';
    size?: 'sm'|'default';
    pill?: boolean;
    keepValueOnUnmount?: boolean;
    disabled?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
    theme: "brand-orange",
});

const fieldState = computed(() => {
    if (props.disabled) return 'disabled';
    if (!!errorMessage.value) return 'invalid';
})

const inputVariants = cva('h-4 w-4 rounded disabled:text-gray-500 disabled:cursor-not-allowed border-gray-300', {
    variants: {
        theme: {
            default: 'text-brand-red focus:ring-brand-orange border-brand-orange disabled:border-gray-300',
        },
        style: {
            'checkbox': 'mt-1',
            'pill': 'hidden'
        },
        state: {
            invalid: 'border-red-600'
        },
    },
    defaultVariants:{
        theme: 'default'
    }
})

const labelVariants = cva('', {
    variants: {
        theme: {
            'brand-orange': '',
            'brand-blue': '',
            'brand-tiffanyBlue': ''
        },
        style: {
            'checkbox': 'text-sm',
            'pill': 'rounded-full border inline-flex font-semibold items-center gap-2 select-none'
        },
        size: {
            sm: '',
            default: ''
        },
        state: {
            checked: ''
        }
    },
    compoundVariants: [
        {
            style: 'pill',
            size: 'default',
            class: 'px-6 py-3'
        },
        {
            style: 'pill',
            size: 'sm',
            class: 'px-4 py-2 text-xs'
        },


        {
            theme: 'brand-tiffanyBlue',
            style: 'pill',
            class: 'text-brand-tiffanyBlue border-brand-tiffanyBlue'
        },
        {
            theme: 'brand-tiffanyBlue',
            style: 'pill',
            state: 'checked',
            class: 'text-brand-blue bg-brand-tiffanyBlue'
        },
        {
            theme: 'brand-blue',
            style: 'pill',
            class: 'border-brand-blue'
        },
        {
            theme: 'brand-blue',
            style: 'pill',
            state: 'checked',
            class: 'text-white bg-brand-blue'
        },
    ],
    defaultVariants: {
        size: 'default'
    }
})

const isValuePrimitive = () => ['number', 'string', 'boolean'].includes(typeof props.value);

if (!isValuePrimitive() && !props.valueToString) {
    console.warn(
        `Please provide {valueToString} for {${props.name}} if {value} is not a primitive`
    );
}

const idAttr = computed(() => {
    if (props.id) return props.id;
    if (!props.value) return;

    if (!isValuePrimitive() && !props.valueToString) return;
    const value = isValuePrimitive()
        ? props.value.toString()
        : props.valueToString!(props.value);
    return "chk-" + kebabCase(props.name) + "-" + kebabCase(value);
});

const {
    errorMessage,
    handleChange,
    checked,
} = useField(
    toRef(props, "name"),
    undefined,
    {
        type: "checkbox",
        checkedValue: props.value,
        uncheckedValue: props.uncheckedValue,
        keepValueOnUnmount: props.keepValueOnUnmount,
        initialValue: props.initialValue
    }
);

const onChange = () => {
    handleChange(checked ? props.value : props.uncheckedValue);
    emit("update:modelValue", checked);
};
</script>
