<template>
    <slot :share="share"></slot>
</template>

<script setup lang="ts">
import { getMetaContentByName } from '@/modules/core/utilities';

const sharingContent = {
    url: location.href,
    title: document.title,
    text: getMetaContentByName('description'),
    pic: getMetaContentByName('image'),
}

const queryParams = new URLSearchParams();

queryParams.set('url', sharingContent.url);
queryParams.set('title', sharingContent.title);

if (sharingContent.pic) {
    queryParams.set('pic', sharingContent.pic);
}

const queryString = queryParams.toString();

const share = () => {
    window.open('http://service.weibo.com/share/share.php?' + queryString);
};

</script>

<style scoped></style>
