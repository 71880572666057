import { InjectionKey, MaybeRef } from "vue";

export interface MainNavigationSectionState {
    /**
     * Used to track if a popover is open in desktop
     */
    // isPopoverActive: MaybeRef<boolean>;

    isSubpageActive?: MaybeRef<boolean>;
    title: MaybeRef<string>;
}


export interface MainNavigationSharedContext {
    sections: MainNavigationSectionState[];
    isDocked: boolean;
    isDrawerActive: boolean;
    isHomePage: boolean;
    lastDirections: {
        top: boolean;
        bottom: boolean
    };
}

export const INJECTION_KEY = Symbol() as InjectionKey<MainNavigationSharedContext>;
