<template>
    <GenericModal
        :unmount="false"
        backdrop="static"
        fullscreen
        scrollable>
        <template #activator="{ show, isActive, hide }">
            <slot name="activator" :isActive="isActive" :hide="hide" :show="show"></slot>
        </template>

        <template #modal-panel="{ show, isActive, hide }">
            <DialogPanel class="flex flex-col flex-grow overflow-hidden">
                <TransitionRoot appear :show="isActive" :unmount="false" as="template">
                    <TransitionChild
                        enter="transition ease-in-out duration-200 delay-75 transform"
                        enter-from="translate-x-full"
                        enter-to="translate-x-0"
                        leave="transition ease-in-out duration-200 delay-75 transform"
                        leave-from="translate-x-0"
                        leave-to="translate-x-full"
                        :unmount="false"
                        as="template">
                        <slot :isActive="isActive" :hide="hide" :show="show"></slot>
                    </TransitionChild>
                </TransitionRoot>
            </DialogPanel>
        </template>

    </GenericModal>



</template>

<script setup lang="ts">
import { TransitionChild, TransitionRoot } from '@headlessui/vue';
import { GenericModal } from '@/modules/core/components';
import { DialogPanel } from '@headlessui/vue';
import { ref } from 'vue';


const isActive = ref(false);

</script>
