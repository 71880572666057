import axios from 'axios';
import { defineStore } from 'pinia';
import endpoints from '../endpoints';
import { ClientBaseContent, UmbracoBaseContent } from '@/modules/website/typings';
import { NewsEventItem } from '../components/news-events/types';

export const useNewsAndEventsStore = defineStore('newsEvents', {

    state: () => ({
        filters: {
            pageNumber: 1,
            searchTerms:'',
            tagId: '',
        },
        newsArticlesList: [] as NewsEventItem[],
        newsEventsTagsList: [] as ClientBaseContent[],
        isBusy: false,
        tagIdAll: {} as UmbracoBaseContent,
    }),
    getters: {
        sampleGetter: state => state,
        filteredItems: state => {
            let filterList: NewsEventItem[] = state.newsArticlesList ?? [];

            if (state.filters.searchTerms !== '') {
                const searchTermLowerCase = state.filters.searchTerms.toLowerCase();
                filterList = filterList.filter(item => item.name.toLowerCase().includes(searchTermLowerCase));
            }
        
            if (state.filters.tagId !== state.tagIdAll.key) {
                filterList = filterList.filter(item => {
                    return item.tags.some(tag => tag.key === state.filters.tagId);
                });
            }
            
            return filterList;
        }

    },
    actions: {
        async getAPIList() {

            try {
                const requestNewsAndEventsList = axios.get<UmbracoBaseContent[]>(endpoints.NEWS_AND_EVENT);

                const [
                    responseNewsAndEventsList,
                ] = await Promise.all([requestNewsAndEventsList]);

                this.$patch({
                    newsArticlesList: responseNewsAndEventsList.data,
                })

            } catch (error) {
                console.error(error);
            }
        }
    }
})