
<template>
    <div ref="textRef" :class="['text-animation', isInView ? 'in-view' : '']">
        <slot></slot>
    </div>
</template>

<script setup lang="ts">
import SplitType from 'split-type';
import { inView } from 'motion';
import { computed, nextTick, onMounted, ref } from 'vue';

interface Props {
    delay?: number;
    runMode?: string;
}

const props = withDefaults(defineProps<Props>(), {
    delay: 0,
    runMode: 'once',
})

const motionOK = !window.matchMedia('(prefers-reduced-motion)').matches;
const textRef = ref<HTMLElement | null>(null);
const isInView = ref(false);

onMounted(() => {
    const el = textRef.value;
    if (!el) return;

    if (!motionOK) {
        isInView.value = true;
        return;
    }

    // console.log('onmounted succesfull');

    nextTick(() => {

        // console.log('splitting.');
        const firstLevelEls = el.querySelectorAll('h1, h2, h3, h4, h5, h6');
        firstLevelEls.forEach(el => {
            el.classList.add('text-anim-first-level')
            SplitType.create(el as HTMLElement, {
                split: 'lines, words'
            })
        });

        const secondLevelEls = el.querySelectorAll('p, ul, ol');
        secondLevelEls.forEach(el => {
            el.classList.add('text-anim-second-level')
            SplitType.create(el as HTMLElement, {
                split: 'words'
            })
        });

        // console.log('splitting done');
        window.addEventListener('load', () => {

            // console.log('add listener');
            inView(textRef.value!, () => {
                isInView.value = true;

                console.log('set inview.');
            })
        }, { once: true })
    })
});

const transitionDelay = computed(() => props.delay ? props.delay + 's' : '0s');
</script>

<style>
@media not (prefers-reduced-motion) {

    .text-animation {
        .whitespace {
            display: inline;
        }

        .word {
            /* overflow: hidden; */
            /* border: 1px solid red; */
        }

        .text-anim-first-level {
            display: inline-block;

            .word {
                opacity: 0;
                transform: translateY(5rem);
                display: inline-block;
            }
        }

        .text-anim-second-level * {
            opacity: 0;
            transform: translateY(3rem);
        }
    }

    html.loaded {
        .text-animation {
            .text-anim-first-level {
                .word {
                    transition-delay: v-bind(transitionDelay);
                    transition-property: opacity, transform;
                    transition-duration: .6s;
                    transition-timing-function: cubic-bezier(.215, .61, .355, 1);
                }
            }

            .text-anim-second-level {
                * {
                    transition-delay: calc(0.3s + v-bind(transitionDelay));
                    transition-property: opacity, transform;
                    transition-duration: .6s;
                    transition-timing-function: cubic-bezier(.215, .61, .355, 1);
                }
            }

            &.in-view {
                .text-anim-first-level {
                    .word {
                        opacity: 1;
                        transform: translateY(0);
                        transition-delay: calc(var(--word-index) * 50ms);
                    }
                }

                .text-anim-second-level * {
                    opacity: 1;
                    transform: translateY(0);
                }
            }
        }
    }


}

</style>
