import { useBreakpoints } from "@vueuse/core";
import { BREAKPOINTS } from "../constants";
import appConstants from "@/modules/website/app.constants";

export function setCssHelperVars() {

    const breakpoints = useBreakpoints(BREAKPOINTS);
    const xlAndGreater = breakpoints.greater('xl');

    const navHeight = xlAndGreater.value ? appConstants.NAV_HEIGHT_XL : appConstants.NAV_HEIGHT;
    const contentMinFullHeight = (window.innerHeight ?? 0);
    document.documentElement.style.setProperty('--nav-height', navHeight + 'px');
    document.documentElement.style.setProperty('--content-min-full-height', contentMinFullHeight + 'px');
}
