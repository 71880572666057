<template>
    <div class="block flex-grow lg:flex-grow-0">
        <label for="default-search" class="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white">Search</label>
        <div class="relative">
            <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                <Icon symbol="search" class="w-5 h-5 text-brand-gold"></Icon>
            </div>
            <input
                type="text"
                id="keyword"
                :name="name"
                :value="inputValue"
                class="block w-full ps-10 px-[12px] py-[14px] lg:min-w-[300px] text-black placeholder:text-brand-gray-200 placeholder:text-[16px] border-[.5px] border-brand-gray-100 rounded-full  focus:ring-brand-gold focus:border-brand-gold"
                placeholder="输入关键词"
                @input="onHandleChange">
        </div>
    </div>
</template>

<script setup lang="ts">
import { Icon } from '@/modules/core/components';
import { useField } from 'vee-validate';

const props = defineProps({
    name: {
        type: String,
        required: true
    },
    modelValue: String
})

const emits = defineEmits<{
    (event: 'textInput'): void
    (event: 'update:modelValue', value: string): void
    (event: 'input-value-changed', data: string): void
}>();

const {
    value: inputValue,
    handleChange,
} = useField<string>(props.name);

const onHandleChange = (e: unknown) => {
    handleChange(e);
    emits('update:modelValue', inputValue.value);
    emits('textInput');
    emits('input-value-changed', inputValue.value);
}

</script>
