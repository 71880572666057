import { Directive } from "vue";

/**
 * 💡 textareas naturally have a height of 2 lines of text
 * @see https://dzone.com/articles/textarea-tag-busted#:~:text=A%20default%20size%20of%20the,defined%20with%2020%20text%20characters.
 */
export const TextareaAutoGrow = {
    mounted (el: HTMLElement) {
        let minHeight: number;

        el = el.tagName.toLowerCase() === 'textarea' ? el : el.querySelector('textarea')!;
        if (!el) return;

        const computedStyles = getComputedStyle(el);
        minHeight = parseFloat(computedStyles.getPropertyValue('min-height'));

        let observe = (element: HTMLElement, event: string, handler: () => void) => {
            element.addEventListener(event, handler, false)
        }

        let resize = () => {
            el.style.height = "auto";
            let border = +el.style.borderTopWidth * 2;
            el.style.setProperty("height", (( el.scrollHeight < minHeight ? minHeight : el.scrollHeight ) + border) + "px", "important");
        }

        let delayedResize = () => {
            window.setTimeout(resize, 0);
        }

        const respondToVisibility = (element: Element, callback: Function) => {
            let intersectionObserver = new IntersectionObserver(
                (entries) => {
                    entries.forEach((entry) => {
                        if (entry.intersectionRatio > 0) {
                            callback()
                        };
                    });
                },
                { root: document.documentElement }
            );
      
            intersectionObserver.observe(element);
        };

        respondToVisibility(el, resize);
        observe(el, "change", resize);
        observe(el, "cut", delayedResize);
        observe(el, "paste", delayedResize);
        observe(el, "drop", delayedResize);
        observe(el, "input", resize);
        observe(el, "textarea:manualResize", delayedResize);
        resize()
    }
} as Directive<any, any>