<template>
    <component :is="as" :class="cn(variants({ theme, intent, size, disabled }), $props.class)" :disabled="disabled">
        <span :class="cn('relative z-1 flex flex-grow items-center justify-center gap-2', buttonClass)">
            <slot>
            </slot>
            <Icon v-if="!hideIcon" symbol="arrow-right" class="w-[14px] h-[14px]"></Icon>
        </span>
    </component>
</template>

<script setup lang="ts">
import { cva, VariantProps } from "class-variance-authority";
import { cn } from "@/modules/core/utilities";
import { Icon } from "@/modules/core/components";

const variants = cva(
    "group relative inline-flex select-none cursor-pointer text-center justify-end p-0 transition duration-200 ease-in-out",
    {
        variants: {
            theme: {
                white: "text-white hover:text-brand-gold [&_svg]:text-brand-red",
                black: "text-black hover:text-brand-red [&_svg]:text-brand-red",
                submit: "text-white bg-brand-red [&_svg]:text-brand-gold [&_svg]:hover:text-brand-red hover:bg-black rounded-full py-[12px] px-[24px]"
            },
            intent: {
                button: "",
                link: "",
                // hover:opacity-80
            },
            size: {
                default: 'text-[18px] lg:text-[20px] font-bold',
                sm: 'body-4 font-bold',
                md: 'text-[18px] lg:heading-4 font-bold'
            },
            disabled: {
                true: "pointer-events-none",
            },
        },
        defaultVariants: {
            theme: "white",
            intent: "link",
            size: "default"
        },
    }
);

interface Props {
    as?: "button" | "a";
    theme?: VariantProps<typeof variants>["theme"];
    intent?: VariantProps<typeof variants>["intent"];
    size?: VariantProps<typeof variants>["size"];
    disabled?: boolean;
    hideIcon?: boolean;
    class?: any;
    buttonClass?: any;
}

withDefaults(defineProps<Props>(), {
    as: "a",
});
</script>

<style scoped></style>
