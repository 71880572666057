<template>
    <div :class="[bgClass, loaderClass, 'relative overflow-hidden']">
        <div class="absolute top-0 bottom-0 left-0 right-0 shimmer" :style="shimmerStyle"></div>
        <slot></slot>
    </div>
</template>
  

<script lang="ts" setup>
import { computed, toRefs } from 'vue';

const LOADER_TYPES = { rectangle: 'rectangle', circle: 'circle' };

const LOADER_CSS_CLASSES = {
    [LOADER_TYPES.rectangle]: 'rounded',
    [LOADER_TYPES.circle]: 'rounded-full',
};

type LoaderTypesKeys = keyof typeof LOADER_TYPES;

const SHIMMER_COLOR = '#ffffff';

const isHexColor = (hexColor: string) => {
    const hex = hexColor.replace('#', '');

    return typeof hexColor === 'string' && hexColor.startsWith('#') && hex.length === 6 && !isNaN(Number('0x' + hex));
};

const hexToRgb = (hex: string) => `${hex.match(/\w\w/g)?.map((x) => +`0x${x}`)}`;

interface Props {
    type?: LoaderTypesKeys;
    bgClass?: string;
    cssClass?: string;
    shimmerColor?: string;
}

const props = withDefaults(defineProps<Props>(), {
    type: 'rectangle',
    bgClass: 'bg-gray-300',
    shimmerColor: SHIMMER_COLOR
})

const { type, bgClass, cssClass, shimmerColor } = toRefs(props);

const shimmerStyle = computed(() => {
    const rgb = isHexColor(shimmerColor.value) ? hexToRgb(shimmerColor.value) : SHIMMER_COLOR;

    return {
        backgroundImage: `linear-gradient(90deg, rgba(${rgb}, 0) 0%, rgba(${rgb}, 0.2) 20%, rgba(${rgb}, 0.5) 60%, rgba(${rgb}, 0))`,
    };
});

const loaderClass = computed(() => (cssClass?.value ? cssClass.value : LOADER_CSS_CLASSES[type.value]));
</script>
  
<style lang="css" scoped>
.shimmer {
    transform: translateX(-100%);
    animation: shimmer 1.4s infinite;
}

@keyframes shimmer {
    100% {
        transform: translateX(100%);
    }
}
</style>
  