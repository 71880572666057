export function currentSearchParams () {
    const url = new URL(window.location.href);
    return url.searchParams
}

export function setReferrerIntoSesionStorage() {
    const referrer = getReferrerFromSesionStorage() || document.referrer;

    if(!referrer) return;

    // We don't want referrer to be the webpage itself
    const origin = window.location.origin;
    if (referrer.startsWith(origin)) return;

    sessionStorage.setItem('referrer', referrer);
}

export function getReferrerFromSesionStorage() {
    return sessionStorage.getItem('referrer');
}


export function getMetaContentByName (name: string) {
    const metaTag = document.querySelector(`meta[name="${name}"]`);
    return metaTag ? metaTag.getAttribute("content") : null;
  };
