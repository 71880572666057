<template>
    <div ref="rootElRef">
        <slot name="activator" :show="onThumbnailClick">
            <div v-if="videoKind === 'html5'" v-bind="$attrs">
                <video
                    ref="videoRef"
                    class="object-cover"
                    :autoplay="autoplay"
                    :playsinline="autoplay"
                    :muted="autoplay"
                    :loop="loop"
                    :controls="isPlaying && !autoplay"
                    :poster="poster"
                    @pause="isPlaying = false"
                    @play="isPlaying = true">
                    <slot></slot>
                </video>
                <FadeTransition>
                    <button
                        v-if="!isPlaying && !showIframeVideo"
                        @click="onThumbnailClick"
                        class="inset-0 bg-black/30 flex items-center justify-center"
                        type="button">
                        <Icon symbol="play-circle" class="text-white opacity-80 h-14 w-14 md:h-24 md:w-24"></Icon>
                        <span class="sr-only">Play Video</span>
                    </button>
                </FadeTransition>
            </div>
            <template v-else>
                <div v-if="!showIframeVideo" v-bind="$attrs">
                    <VLazyImage
                        class="object-cover w-full h-full"
                        :src="poster"
                    ></VLazyImage>
                    <FadeTransition>
                        <button
                            v-if="!isPlaying && !showIframeVideo"
                            @click="onThumbnailClick"
                            class="inset-0 bg-black/30 flex items-center justify-center"
                            type="button">
                            <Icon symbol="play" class="text-white opacity-80 h-14 w-14 md:h-24 md:w-24"></Icon>
                            <span class="sr-only">Play Video</span>
                        </button>
                    </FadeTransition>
                </div>
                <div v-else v-html="iframe" v-bind="$attrs"></div>
            </template>
        </slot>
    </div>

    <GenericModal
        ref="modalRef"
        size="xl"
        backdrop-opacity-class="bg-black/90"
        modal-panel-background-class="bg-transparent">
        <template #modal-panel="{ hide }">
            <DialogPanel>

                <transition
                    appear
                    enter-from-class="opacity-0"
                    enter-to-class="opacity-100"
                    enter-active-class="transition duration-500 ease-in-out delay-200"
                    leave-from-class="opacity-100"
                    leave-to-class="opacity-0"
                    leave-active-class="transition duration-500 ease-in-out">
                    <button
                       ref="fallbackInitialFocusElRef1"
                       type="button"
                       @click="hide"
                       class="absolute top-0 end-0 p-8 text-white focus:outline-none hover:text-brand-gray-100 hover:scale-110 transition duration-200 ease-in-out">
                       <span class="sr-only">
                           Close modal
                       </span>
                       <Icon symbol="x" class="w-10 h-10" />
                   </button>
                </transition>

                <transition
                    appear
                    enter-from-class="opacity-0"
                    enter-to-class="opacity-100"
                    enter-active-class="transition duration-500 ease-in-out"
                    leave-from-class="opacity-100"
                    leave-to-class="opacity-0"
                    leave-active-class="transition duration-500 ease-in-out">

                    <video
                        v-if="videoKind === 'html5'"
                        ref="modalVideoRef"
                        autoplay
                        controls>
                        <slot></slot>
                    </video>
                    <div
                        v-else
                        ref="iframeVideoWrapperRef"
                        class="aspect-w-16 aspect-h-9"
                        v-html="iframe"
                    ></div>
                </transition>

            </DialogPanel>
        </template>
    </GenericModal>
</template>

<script setup lang="ts">
import { FadeTransition, GenericModal, Icon, VLazyImage } from '@/modules/core/components';
import { DialogPanel } from '@headlessui/vue';
import { ref, computed, } from 'vue';

interface Props {
    autoplay?: boolean;
    loop?: boolean;
    poster?: string;
    popup?: boolean;
    videoKind?: 'html5'|'iframe';
    url?: string;
}

defineOptions({
    inheritAttrs: false
})

const props = withDefaults(defineProps<Props>(), {
    url: '',
})

const videoRef = ref<HTMLVideoElement | null>();
const iframeVideoWrapperRef = ref<HTMLElement | null>();

const modalVideoRef = ref<HTMLVideoElement | null>();

const modalRef = ref<InstanceType<typeof GenericModal>>();

const isPlaying = ref(false);

const showIframeVideo = ref(false);

const iframe = computed(() => {
    if (!props.url) return;

    const templt = document.createElement('template');
    templt.innerHTML = props.url?.trim();

    const iframe = templt.content.querySelector('iframe');
    if (!iframe) return;

    const src = new URL(iframe.src);

    if (src.protocol !== 'https:') {
        src.protocol = 'https:';
    }

    src.searchParams.set('enablejsapi', '1');
    iframe?.setAttribute('src', src.toString());

    return templt.innerHTML;
});

const rootElRef = ref<HTMLElement>();

const playIframeVideo = () => {
    if (!props.popup) {
        setTimeout(() => {
            const iframe = rootElRef.value?.querySelector('iframe');
            iframe?.contentWindow?.postMessage('{"event":"command","func":"playVideo","args":""}', '*')
        }, 1000);
        return;
    }

    setTimeout(() => {
        const iframe = iframeVideoWrapperRef.value?.querySelector('iframe');
        iframe?.contentWindow?.postMessage('{"event":"command","func":"playVideo","args":""}', '*')
    }, 1000)
}

const onThumbnailClick = () => {
    if (props.popup) {
        modalRef.value?.show()

        playIframeVideo()
        return;
    }

    if (props.videoKind === 'html5') {
        if (!videoRef.value) return;

        if (!videoRef.value.played || videoRef.value.paused) {
            videoRef.value?.play();
            return;
        }

        videoRef.value.pause();
    }

    if (props.videoKind === 'iframe') {
        showIframeVideo.value = true;
        playIframeVideo()
    }
}

</script>

<style scoped>

</style>
