<template>
    <label
        :for="htmlFor"
        :class="cn('absolute text-brand-rock duration-300 top-[1rem] sm:top-[0.95rem] md:top-[1.15rem] z-1 origin-[0] peer-focus:text-brand-blue peer-focus:scale-75 peer-focus:-translate-y-3 peer-[&:-webkit-autofill]:scale-75 peer-[&:-webkit-autofill]:-translate-y-3 pointer-events-none', props.class, {
            'left-12': iconName && iconPosition === 'left',
            'left-4': !iconName || (iconName && iconPosition === 'right'),
            'peer-focus:text-red-600': errorMessage,
            'scale-75 -translate-y-3 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0': fieldType === 'input', 
            'scale-100 translate-y-0': fieldType === 'select',
            'scale-75 -translate-y-3': fieldType === 'select' && hasSelectedValue
        })"
    >
        <slot></slot>
    </label>
</template>

<script lang="ts" setup>
import { cn } from '@/modules/core/utilities';

interface Props {
    htmlFor?: string;
    class?: string;
    errorMessage?: string;
    iconName?: string;
    hasSelectedValue?: boolean;
    iconPosition?: 'left'|'right';
    fieldType?: 'input'|'select';
}

const props = withDefaults(defineProps<Props>(), {
    iconPosition: 'left',
    fieldType: 'input'
})

</script>