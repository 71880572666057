<template>
    <div class="container">
        <SwiperVue
            :modules="[FreeMode, Thumbs]"
            :spaceBetween="10"
            :speed=300
            round-lengths
            mousewheel
            grab-cursor
            :thumbs="{ swiper: swiperInstance }"
            @slide-change="onMainSlideChange"
            class="relative [&_.swiper-button-prev]:hidden [&_.swiper-button-next]:hidden lg:[&_.swiper-button-prev]:flex lg:[&_.swiper-button-next]:flex"
            @swiper="onSwiperMain">
           <slot name="main-items"></slot>

            <div class="hidden xl:flex absolute w-full top-1/2 -translate-y-1/2 justify-between z-10">
                <button
                    @click="slideToPrev"
                    :disabled="mainPagination.activeIndex == 0 ? true : false "
                    class="[&_svg]:text-brand-gold [&_svg]:hover:text-black [&_svg]:disabled:text-gray-200 disabled:border-gray-200 disabled:pointer-events-none duration-200 ease-in">
                    <Icon symbol="circle-arrow-left" class="w-10 h-10 transition duration-200 ease-in-out"></Icon>
                </button>
                <button
                    @click="slideToNext"
                    :disabled="mainPagination.activeIndex === mainPagination.totalSlides - 1 ? true : false "
                    class="[&_svg]:text-brand-gold [&_svg]:hover:text-black [&_svg]:disabled:text-gray-200 disabled:border-gray-200 disabled:pointer-events-none duration-200 ease-in">
                    <Icon symbol="circle-arrow-right" class="w-10 h-10 transition duration-200 ease-in-out"></Icon>
                </button>
            </div>

        </SwiperVue>
    </div>


    <div class="hidden xl:block container overflow-visible pb-12">
        <SwiperVue
            :modules="[FreeMode, Thumbs]"
            class="
                h-full
                !overflow-visible
                [&_.swiper-slide]:transition
                [&_.swiper-slide]:opacity-30
                [&_.swiper-slide.swiper-slide-visible]:opacity-100
                [&_.swiper-pagination-bullets]:flex [&_.swiper-pagination-bullets]:justify-center
                [&_.swiper-slide.swiper-slide-thumb-active_.overlay]:opacity-100
                [&_.swiper-slide.swiper-slide-thumb-active_.content]:!items-center
                [&_.swiper-slide.swiper-slide-thumb-active_.arrow]:block
                "
            :speed=300
            round-lengths
            mousewheel
            grab-cursor
            :spaceBetween="20"
            :slidesPerView="2.2"
            :breakpoints="{
                400: {
                    slidesPerView:3.2
                },
                768: {
                    slidesPerView:5
                },
                1200: {
                    slidesPerView:6
                }
            }"
            :pagination="{
                type:'bullets'
            }"
            :loop="true"
            @slide-change="onSlideChange"
            @swiper="onSwiper">

            <slot name="thumbnail-items"></slot>

            <div class="absolute bottom-0 z-10 flex items-center gap-2 left-1/2 -translate-x-1/2">
                <!-- <CarouselBullet
                    v-for="(bullet, index) in pagination.totalSlides"
                    :index="index"
                    pagination-type="bullet"
                    bullet-color="white"
                    :active-index="pagination.activeIndex">
                    <slot name="custom-pagination"></slot>
                </CarouselBullet> -->
                <CarouselBullet
                    v-for="(bullet, index) in mainPagination.totalSlides"
                    :index="index"
                    pagination-type="bullet"
                    bullet-color="white"
                    :active-index="mainPagination.activeIndex">
                    <slot name="custom-pagination"></slot>
                </CarouselBullet>
            </div>

        </SwiperVue>
    </div>

    <div class="xl:hidden container flex justify-center items-center gap-4 pb-8">
        <button
            @click="slideToPrev"
            :disabled="mainPagination.activeIndex == 0 ? true : false "
            class="[&_svg]:text-white [&_svg]:hover:text-brand-gold [&_svg]:disabled:text-gray-200 disabled:border-gray-200 [&_svg]:disabled:opacity-50 disabled:opacity-50 disabled:pointer-events-none duration-200 ease-in">
            <Icon symbol="circle-arrow-left" class="w-10 h-10 transition duration-200 ease-in-out"></Icon>
        </button>
        <div class="flex gap-2">
            <CarouselBullet
                v-for="(bullet, index) in mainPagination.totalSlides"
                :index="index"
                pagination-type="bullet"
                bullet-color="white"
                :active-index="mainPagination.activeIndex">
                <slot name="custom-pagination"></slot>
            </CarouselBullet>
        </div>
        <button
            @click="slideToNext"
            :disabled="mainPagination.activeIndex === mainPagination.totalSlides - 1 ? true : false "
            class="[&_svg]:text-white [&_svg]:hover:text-brand-gold [&_svg]:disabled:text-gray-200 disabled:border-gray-200 [&_svg]:disabled:opacity-50 disabled:opacity-50 disabled:pointer-events-none duration-200 ease-in">
            <Icon symbol="circle-arrow-right" class="w-10 h-10 transition duration-200 ease-in-out"></Icon>
        </button>
    </div>
</template>

<script setup lang="ts">
import Swiper from 'swiper';
import {  Swiper as SwiperVue } from 'swiper/vue';
import { FreeMode, Thumbs } from 'swiper/modules';

import { reactive, ref } from 'vue';
import CarouselBullet from '@/modules/core/components/carousel/carousel-bullet.vue';
import { Icon } from '@/modules/core/components';

import 'swiper/css';
import "swiper/css/free-mode"
import "swiper/css/thumbs"
import { useBreakpoints } from '@vueuse/core';
import { BREAKPOINTS } from '@/modules/core/constants';

const swiperInstance = ref<Swiper>();
const swiperMainInstance = ref<Swiper>();

const pagination = reactive({
    activeIndex: 0,
    totalSlides: 0
})

const mainPagination = reactive({
    activeIndex: 0,
    totalSlides: 0
})

const breakpoints = useBreakpoints(BREAKPOINTS);
const xlAndGreater = breakpoints.greater('xl');


const onSlideChange = (swiper: Swiper) => {
    pagination.activeIndex = swiper.realIndex;
    pagination.totalSlides = swiper.slides.length;
}

const onMainSlideChange = (swiper: Swiper) => {
    mainPagination.activeIndex = swiper.realIndex;
    mainPagination.totalSlides = swiper.slides.length;
}


const onSwiper = (swiper: Swiper) => {
    swiperInstance.value = swiper;
    pagination.activeIndex = swiper.realIndex;
    pagination.totalSlides = swiper.slides.length;
}

const onSwiperMain = (swiper: Swiper) => {
    swiperMainInstance.value = swiper;
    mainPagination.activeIndex = swiper.realIndex;
    mainPagination.totalSlides = swiper.slides.length;
}

const slideToPrev = () => {
    if (swiperMainInstance.value) {
        swiperMainInstance.value.slidePrev();
    }
};

const slideToNext = () => {
    if (swiperMainInstance.value) {
        swiperMainInstance.value.slideNext();
    }
};


</script>

<style lang="scss">
.swiper-wrapper {
    transition-delay: 0.113554ms;
    -webkit-transition-property: -webkit-transform;
    transition-property: -webkit-transform;
    transition-property: transform;
    transition-property: transform,-webkit-transform;
}
</style>
