import { Toast } from '@/modules/core/types';
import { ref } from 'vue';

const toasts = ref<Array<Toast>>([]);

export const useNotifications = () => {

    const showToast = (toast: Toast) => {
        toasts.value.push(toast)
    }

    const handleToastHidden = (toast: Toast) => {
        const index = toasts.value.indexOf(toast);
        if (index !== -1) {
            toasts.value.splice(index, 1);
        }
    }

    return {
        toasts,
        showToast,
        handleToastHidden
    }
}