<template>
    <slot name="activator" :show="show" :hide="hide" :isActive="isActive" />
    <TransitionRoot
        :show="isActive"
        appear
        :unmount="unmount"
        as="template">
        <Dialog
            as="div"
            @close="onDialogClose"
            class="relative z-50"
            :initial-focus="initialFocusElRef"
            :unmount="unmount">

            <TransitionChild
                as="template"
                enter="duration-300 ease-out"
                enter-from="opacity-0"
                enter-to="opacity-100"
                leave="duration-200 ease-in"
                leave-from="opacity-100"
                leave-to="opacity-0"
                appear
                :unmount="unmount">
                <div :class="cn('fixed inset-0', backdropOpacityClass)" aria-hidden="true"></div>
            </TransitionChild>

            <div class="fixed top-0 left-0 w-full h-full overflow-x-hidden overflow-y-auto">
                <div :class="cn(
                        'flex flex-col justify-center mx-auto p-4 max-w-full',
                        {
                            'p-0': fullscreen,
                            'min-h-full': dialogCentered,
                            'h-full': scrollable
                        },
                        dialogClass
                    )">
                    <TransitionChild
                        enter="duration-300 ease-out"
                        enter-from="opacity-0"
                        enter-to="opacity-100"
                        leave="duration-200 ease-in"
                        leave-from="opacity-100"
                        leave-to="opacity-0"
                        :class="[
                            'w-full flex flex-col mx-auto',
                            modalPanelBackgroundClass,
                            {
                                'overflow-hidden max-h-full': scrollable || fullscreen,
                                'flex-grow': fullscreen
                            }
                        ]"
                        appear
                        :unmount="unmount">
                        <!-- Only use template #modal-panel if you decide to have full control on how the panel looks like -->
                        <!-- Make sure to use <DialogPanel> when overriding -->
                        <slot name="modal-panel" :show="show" :hide="hide" :isActive="isActive">
                            <DialogPanel
                                :class="cn(
                                    'flex flex-col',
                                    {
                                        'overflow-hidden': scrollable || fullscreen,
                                        'flex-grow': fullscreen
                                    }
                                )">
                                <slot name="modal-header" :show="show" :hide="hide" :isActive="isActive">
                                    <div class="flex items-center justify-end w-full px-5 py-4 md:py-8 md:px-8">
                                        <slot name="modal-title">
                                            <DialogTitle v-if="modalTitle" class="heading-4 text-brand-orange">
                                                {{ modalTitle }}
                                            </DialogTitle>
                                        </slot>
                                        <button
                                            ref="fallbackInitialFocusElRef1"
                                            type="button"
                                            @click="hide"
                                            class="text-brand-darkBlue focus:outline-none">
                                            <span class="sr-only">
                                                Close modal
                                            </span>
                                            <Icon symbol="close" class="w-7 h-7" />
                                        </button>
                                    </div>
                                </slot>
                                <div ref="fallbackInitialFocusElRef2"
                                    :class="[
                                        bodyClass,
                                        scrollable ? 'overflow-y-auto' : ''
                                    ]">
                                    <slot :show="show" :hide="hide" :isActive="isActive"></slot>
                                </div>
                            </DialogPanel>
                        </slot>
                    </TransitionChild>
                </div>
            </div>
        </Dialog>
    </TransitionRoot>
</template>

<script lang="ts" setup>
import { cn } from '@/modules/core/utilities';
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue';
import { computed, ref, toRef } from 'vue';
import { Icon } from '../common';

const fallbackInitialFocusElRef1 = ref<HTMLElement|null>(null);
const fallbackInitialFocusElRef2 = ref<HTMLElement|null>(null);

interface Props {
    unmount?: boolean;
    initialFocusElRef?: HTMLElement|null;
    backdrop?: boolean|'static';
    keyboard?: boolean;
    fullscreen?: boolean;
    size?: 'sm'|'lg'|'xl';
    dialogCentered?: boolean;
    scrollable?: boolean;
    modalTitle?: string;
    bodyClass?: string;
    eager?: boolean;
    backdropOpacityClass?: string;
    modalPanelBackgroundClass?: string;
}

const props = withDefaults(defineProps<Props>(), {
    unmount: true,
    bodyClass: 'px-5 pb-8 sm:pb-10 md:px-8 flex-grow flex-shrink flex-auto',
    backdropOpacityClass: 'bg-black/50',
    dialogCentered: true,
    modalPanelBackgroundClass: 'bg-white'
})

const emit = defineEmits<{
    (e: 'show'): void,
    (e: 'hide'): void,
}>()

const initialFocusElToRef = toRef(props, 'initialFocusElRef');
const isActive = ref(false);

// Assign fallback elements to focus for accessibility
if(!props.initialFocusElRef) {
    if(fallbackInitialFocusElRef1.value) {
        initialFocusElToRef.value =  fallbackInitialFocusElRef1.value;
    } else if (fallbackInitialFocusElRef2.value) {
        initialFocusElToRef.value =  fallbackInitialFocusElRef2.value;
    }
}

const dialogClass = computed(() => {
    if (props.fullscreen) return 'w-full';
    if (props.size === 'sm') return 'w-80';
    if (props.size === 'lg') return 'w-[800px]';
    if (props.size === 'xl') return 'w-[1140px]';
    return 'w-[500px]';
})

const show = () => {
    emit('show');
    isActive.value = true;
}

const hide = () => {
    emit('hide');
    isActive.value = false;
}

const onDialogClose = () => {
    if (props.backdrop === 'static') return;
    hide();
}

if (props.eager) {
    show()
}

defineExpose({
    show,
    hide
})
</script>
