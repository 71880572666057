<template>
    <form :class="cn('sticky lg:relative bg-white top-[calc(var(--nav-height))] lg:top-0 z-10 transition duration-500 delay-300 ease-in-out', {
        'top-0': lastDirections.bottom
    })">
        <div class="hidden lg:flex flex-col mb-8 lg:mb-12">
            <div class="flex justify-between items-center pt-5 pb-8 lg:pb-12">
                <h2 class="heading-2 font-bold text-start pb-0">全部动态</h2>
                <NewsEventsListingSearch
                    name="searchTerms"
                    @input-value-changed="searchTermsChanged"
                ></NewsEventsListingSearch>
            </div>

            <PillRadioGroup
                :items="newsEventsTagsList"
                item-text="label"
                item-value="value"
                name="tagId"
                class="w-full"
                option-class="flex h-full w-full"
                group-options-class="grid grid-cols-5 xl:grid-cols-9 gap-3 space-x-0"
                inner-option-class=""
                @input-value-changed="tagListIdChanged">
                <template #radio-group-label="{ item, checked, index }">
                    <p class="flex justify-center items-center gap-2 text-[18px] text-center font-bold line-clamp-2">
                        <Icon :key="index" symbol="arrow-right" class="w-3 h-3 text-brand-red transition duration-500 ease-in-out hidden" :class="{ 'block' : checked }"></Icon>
                        {{ item.label }}
                    </p>
                </template>
            </PillRadioGroup>
        </div>

        <div class="block lg:hidden">
            <NewsEventsOffcanvas>
                <template #activator="{ show }">
                    <div class="pt-4">
                        <NewsEventsListingSearch
                            name="searchTerms"
                            @input-value-changed="searchTermsChanged"
                        ></NewsEventsListingSearch>
                    </div>
                    <div class="flex justify-between items-center py-4 gap-8">
                        <button class="heading-m font-bold text-brand-gold whitespace-nowrap" type="button" @click="show">
                            {{ filterLabel }} ({{ FilterSelected }})
                        </button>
                        <p class="text-brand-gray-200 text-end">筛选已应用: {{ FilterSelected }}</p>
                    </div>
                </template>
                <template #="{ hide }">
                    <div class="container h-full overflow-y-auto">
                        <div class="h-[--nav-height] flex justify-end items-center">
                            <button type="button" @click="hide">
                                <Icon symbol="x" class="w-[30px] h-[30px] text-black"></Icon>
                            </button>
                        </div>

                        <div class="flex justify-end items-center pb-3">
                            <button
                                @click="removeAllFilters"
                                class="text-[16px] lg:text-[18px] text-brand-red font-bold underline hover:text-black disabled:text-brand-gray-200 disabled:select-none transition duration-200 ease-in-out"
                                >
                                {{ clearAllFilterLabel }} ({{ FilterSelected }})
                            </button>
                        </div>

                        <PillRadioGroup
                            :items="newsEventsTagsList"
                            item-text="label"
                            item-value="value"
                            name="tagId"
                            class="w-full"
                            option-class="flex h-full w-full"
                            group-options-class="grid grid-cols-3 xl:grid-cols-9 gap-3 space-x-0"
                            inner-option-class=""
                            @input-value-changed="tagListIdChanged">
                            <template #radio-group-label="{ item, checked, index }">
                                <p class="flex justify-center items-center gap-2 text-[14px] text-center font-bold whitespace-nowrap">
                                    <Icon :key="index" symbol="arrow-right" class="w-3 h-3 text-brand-red hidden transition duration-500 ease-in-out" :class="{ 'block' : checked }"></Icon>
                                    {{ item.label }}
                                </p>
                            </template>
                        </PillRadioGroup>
                    </div>
                </template>
            </NewsEventsOffcanvas>
        </div>
    </form>
</template>

<script setup lang="ts">
import { PillRadioGroup } from '../form';
import { cn } from '@/modules/core/utilities';
import NewsEventsListingSearch from './news-events-listing-search.vue';
import NewsEventsOffcanvas from './news-events-offcanvas.vue';
import { Icon } from '@/modules/core/components';
import { storeToRefs } from 'pinia';
import { useNewsAndEventsStore } from '../../stores/news-events';
import { computed, onMounted, ref, watch } from 'vue';
import { useDebounce, useScroll, useUrlSearchParams } from '@vueuse/core';
import { useForm } from 'vee-validate';

interface Props {
    filterLabel: string;
    clearAllFilterLabel: string;
}

defineProps<Props>();

const FilterSelected = computed(() => {
    const selectedTagId = filters.value.tagId;
    const selectedTag = newsEventsTagsList.value.find(tag => tag.value === selectedTagId);

    return selectedTag ? selectedTag.label : '';
})

const newsEventsStore = useNewsAndEventsStore();
const { isBusy, newsEventsTagsList, filters } = storeToRefs(newsEventsStore);

const params = useUrlSearchParams('history');

const lastDirections = ref({ top: false, bottom: false });
const { y, directions } = useScroll(
    window,
    {
        onScroll: () => {
            lastDirections.value.top = directions?.top;
            lastDirections.value.bottom = directions?.bottom;
        }
    }
);

const searchTermValue = ref<string>();
const debouncedSearchTermValue = useDebounce(searchTermValue, 400);

const {
    resetForm,
    setValues,
} = useForm({});

const searchTermsChanged = (newSearchTerm: string) => {
    isBusy.value = true;
    searchTermValue.value = newSearchTerm;
}

watch(debouncedSearchTermValue, (newSearchTerm) => {
    isBusy.value = false;
    filters.value.searchTerms = newSearchTerm || '';

    updateRoute();
});

const tagListIdChanged = (value: string | number | Array<string | number>) => {
    filters.value.tagId = value as string;

    updateRoute();
}

const removeAllFilters = () => {
    setValues({
        tagIds: [],
    });

    tagListIdChanged([])
}

const updateRoute = () => {
    params.searchTerms = filters.value.searchTerms;
    params.tagId = filters.value.tagId;
}

const initializeFiltersFromQuery = () => {
    const { searchTerms, tagId } = params;

    if (searchTerms) {
        filters.value.searchTerms = searchTerms as string;
    }

    if (tagId) {
        filters.value.tagId = tagId as string;
    }

    resetForm({
        values: {
            searchTerms: filters.value.searchTerms,
            tagId: filters.value.tagId,
            
        }
    })
};

onMounted(() => {
    initializeFiltersFromQuery();
})

newsEventsStore.getAPIList();
</script>

<style scoped>

</style>