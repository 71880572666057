import appConstants from "@/modules/website/app.constants";
import { useBreakpoints } from "@vueuse/core";
import { BREAKPOINTS } from "../constants";
import { scrollBy, scrollIntoView } from "seamless-scroll-polyfill";

export function setScrollbarWidthCssVar() {
    // Creating invisible container
    const outer = document.createElement('div');
    outer.style.visibility = 'hidden';
    outer.style.overflow = 'scroll'; // forcing scrollbar to appear
    document.body.appendChild(outer);

    // Creating inner element and placing it in the container
    const inner = document.createElement('div');
    outer.appendChild(inner);

    // Calculating difference between container's full width and the child width
    const scrollbarWidth = (outer.offsetWidth - inner.offsetWidth);

    // Removing temporary elements from the DOM
    if (outer.parentNode) {
        outer.parentNode.removeChild(outer);
    }

    document.documentElement.style.setProperty('--scrollbar-width', scrollbarWidth + 'px');
}

export function scrollTo(target?: string) {

    const breakpoints = useBreakpoints(BREAKPOINTS);
    const xlAndGreater = breakpoints.greater('xl');

    const offset = xlAndGreater.value ? appConstants.NAV_HEIGHT_XL : appConstants.NAV_HEIGHT;
    const targetElement = target && document.getElementById(target);
    const scrollPosition = targetElement && targetElement.getBoundingClientRect().top + window.scrollY - offset;

    console.log(`scrollPosition ${target} : ${scrollPosition}`);

    if (scrollPosition) {

        window.scrollTo({
            top: scrollPosition,
            behavior: 'smooth',
        });
        return;
    }

    if (target === 'top') {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    }
}

export function seamlessScrollTo(target?: string) {

    const breakpoints = useBreakpoints(BREAKPOINTS);
    const xlAndGreater = breakpoints.greater('xl');

    const offset = xlAndGreater.value ? appConstants.NAV_HEIGHT_XL : appConstants.NAV_HEIGHT;
    const targetElement = target && document.getElementById(target);

    if (target === 'top') {
        scrollBy(window, {
            top: -window.scrollY,
            behavior: 'smooth',
        });
    }

    if (targetElement) {
        scrollBy(window, {
            top: targetElement?.getBoundingClientRect().top - offset,
            behavior: 'smooth',
        });
    }
}

export function seamlessScrollIntoView(target?: string) {
    const targetElement = target && document.getElementById(target);
    if (!targetElement) return;
    scrollIntoView(targetElement, { behavior: 'smooth', block: 'start' });
}
