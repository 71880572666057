<template>
    <slot :share="share"></slot>
    <GenericModal
        ref="genericModalRef"
        modal-panel-background-class="bg-white rounded-sm p-4"
        size="sm">
        <template #modal-panel="{ isActive }">
            <DialogPanel class="flex flex-col flex-grow overflow-hidden">
                <TransitionRoot appear :show="isActive" :unmount="false" as="template">
                    <TransitionChild
                        enter="transition ease-in-out duration-200 delay-75 transform"
                        enter-from="opacity-0"
                        enter-to="opacity-100"
                        leave="transition ease-in-out duration-200 delay-75 transform"
                        leave-from="opacity-100"
                        leave-to="opacity-0"
                        :unmount="false"
                        as="template">
                        <div class="flex flex-col justify-center items-center">
                            <img :src="qrcode" alt="wechat-qr">
                            <p class="text-[14px] lg:text-[16px] text-black">扫描二维码分享至微信</p>
                        </div>
                    </TransitionChild>
                </TransitionRoot>
            </DialogPanel>
        </template>
    </GenericModal>
</template>

<script setup lang="ts">
import { GenericModal } from '@/modules/core/components';
import { DialogPanel, TransitionChild, TransitionRoot } from '@headlessui/vue';
import { ref } from 'vue';
import { useQRCode } from '@vueuse/integrations/useQRCode';
import { getMetaContentByName } from '@/modules/core/utilities';

const sharingContent = {
    url: location.href,
    title: document.title,
    text: getMetaContentByName('description'),
}

const genericModalRef = ref<InstanceType<typeof GenericModal>>();
const qrcode = useQRCode(sharingContent.url)

const share = () => {
    genericModalRef.value?.show();
}

</script>

<style scoped></style>
