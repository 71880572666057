<script lang="ts">
import { defineComponent, onMounted } from 'vue';

import {
    Icon,
    SvgIcon,
    VLazyImage,
    VLazyVideo,
    GenericCarousel,
    GenericModal,
    FadeTransition,
    TextTransition,
    TextTransitionV2,
    TextTransitionV3,
    SlideYUpTransition,
    ScaleTransition,
    // Fancybox,
    PageOutline,
    PageOutlineSection,
    PageOutlineNav,

} from '@/modules/core/components';
import {
    GenericButton,
    MainNavigation,
    MainNavigationItem,
    TileCarousel,
    AnalyticCard,
    InterestForm,
    AnnoucementBar,
    CampusThumbnailCarousel,
    ProgrammeIntake,
    VideoPopup,
    AdmissionAccordion,
    Scholarship,
    ScrollToTopButton,
    NewsEvents,
    WeiboSharing,
    WechatSharing,
    NativeSharing,
} from '@/modules/website/components';

import { SwiperSlide } from 'swiper/vue';
import { seamlessScrollTo, setCssHelperVars, setScrollbarWidthCssVar } from '../core/utilities';
import { DialogPanel } from '@headlessui/vue';
import { useBrowserLocation, useDebounceFn } from '@vueuse/core';

export default defineComponent({
    components: {
        GenericButton,
        Icon,
        SvgIcon,
        VLazyImage,
        VLazyVideo,
        GenericCarousel,
        GenericModal,
        DialogPanel,
        FadeTransition,
        TextTransition,
        TextTransitionV2,
        TextTransitionV3,
        SlideYUpTransition,
        ScaleTransition,
        MainNavigation,
        MainNavigationItem,
        TileCarousel,
        AnalyticCard,
        InterestForm,
        SwiperSlide,
        AnnoucementBar,
        CampusThumbnailCarousel,
        ProgrammeIntake,
        VideoPopup,
        AdmissionAccordion,
        Scholarship,
        ScrollToTopButton,
        // Fancybox,
        PageOutline,
        PageOutlineSection,
        PageOutlineNav,
        NewsEvents,
        WeiboSharing,
        WechatSharing,
        NativeSharing,
    },
    setup() {

        onMounted(() => {
            const debounceFunctions = useDebounceFn(() => {
                setCssHelperVars();
                setScrollbarWidthCssVar();
            }, 200);

            setCssHelperVars();
            setScrollbarWidthCssVar();

            window.addEventListener("resize", debounceFunctions);
        })

        // This will be fired when the page is fully loaded. Technically this will be call after the onMounted.
        window.addEventListener('load', () => {
            document.documentElement.classList.add('loaded');
            process.env.NODE_ENV !== "production" ? document.body.classList.add('debug-screens') : null;

            const location = useBrowserLocation()
            // console.log('route.hash', location.value.hash);
            const hashId = location.value.hash as string;
            if (!hashId) return;
            setTimeout(() => seamlessScrollTo(hashId), 500);
        });

        return {
        }
    }
})
</script>
