<template>
    <div ref="textTransitionV2Ref"
        class="text-transition-v2 [&_.line]:relative [&_.line]:block [&_.words]:inline-block [&_.line]:overflow-hidden">
        <slot></slot>
    </div>
</template>

<script lang="ts" setup>
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import Splitting from 'splitting';
import { onMounted, ref } from 'vue';

gsap.registerPlugin(ScrollTrigger);

const props = defineProps({
    delay: {
        type: Number,
        default: 0.3
    }
})

const print = window.matchMedia('print').matches;
const motionOK = !window.matchMedia('(prefers-reduced-motion)').matches || !print;

const textTransitionV2Ref = ref<HTMLElement>();

onMounted(() => {
    if (!textTransitionV2Ref.value) return;
    if (!motionOK) {
        textTransitionV2Ref.value.classList.remove('invisible');
        return;
    };

    let revealText = textTransitionV2Ref.value!.querySelectorAll('h1, h2, h3, h4, h5, h6, p');
    let results: any = Splitting({ target: revealText, by: "lines" });

    results.forEach((splitResult: Record<string, any>) => {
        const wrappedLines = splitResult.lines!
            .map(
                (wordsArr: Record<string, any>) => `
                <span class="line"><div class="words">
                    ${wordsArr
                        .map(
                            (word: HTMLElement) => `${word.outerHTML}<span class="whitespace">
                    </span>`
                        )
                        .join("")}
                </div></span>`
            )
            .join("");
        (splitResult.el as HTMLElement).innerHTML = wrappedLines;
    });

    let tl = gsap.timeline({
        scrollTrigger: {
            trigger: textTransitionV2Ref.value,
            toggleActions: "restart none none reset"
        }
    });

    textTransitionV2Ref.value.classList.remove('invisible')

    tl.from(textTransitionV2Ref.value.querySelectorAll('.line .words'), {
        yPercent: 120,
        stagger: 0.1,
        duration: 1,
        delay: props.delay,
    });
})

</script>

<style lang="scss"></style>
